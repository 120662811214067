import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// actions
import { googleLogIn } from "redux/actions/user";
// components
import { Flex } from "@chakra-ui/react";
import ConfirmAction from "components/modal/ConfirmAction/ConfirmAction";
// utils
import { useModal } from "hooks/useModal";

export default function GoogleAuth({ isMobile }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpen, openModal, closeModal, setIsOpen] = useModal(false);

  const handleError = (error) => {
    console.log(error);
  };

  const handleSuccess = (credentials) => {
    dispatch(googleLogIn(credentials.credential)).then(function (res) {
      if (res && res.name === "AxiosError") {
        setIsOpen(true);
      }
    });
  };

  const registerRedirect = () => {
    history.push("/auth/register");
  };

  return (
    <Flex
      justifyContent={"center"}
      style={{ marginBottom: 15, width: "100%", marginTop: 30 }}
    >
      <GoogleLogin
        onError={handleError}
        onSuccess={handleSuccess}
        shape="circle"
        theme="filled_white"
        useOneTap={true}
        width={isMobile ? 260 : 400}
        locale="en"
      />
      <ConfirmAction
        isOpen={isOpen}
        closeModal={closeModal}
        width={isMobile ? 330 : 400}
        height={200}
        action={"Error"}
        question={
          "This email don't exist in our database. Try with another account, or register!"
        }
        cancelFunction={closeModal}
        cancelText={"Close"}
        confirmFunction={() => registerRedirect()}
        confirmText={"Register"}
      />
    </Flex>
  );
}
