import React from "react";
import style from "../styles/quotationDetail.module.css";
// components
import { Flex } from "@chakra-ui/react";
import CustomLabel from "components/customLabel/CustomLabel";
import FormInput from "components/inputs/FormInput/FormInput";
import FormCustomCheckbox from "components/inputs/FormCustomCheckbox/FormCustomCheckbox";

const SampleWithoutType = ({ data }) => {
  return (
    <Flex justifyContent={"space-between"} gap={5}>
      <section className={style.sectionContainer}>
        <CustomLabel name={"Number of samples"} color={"#525c60"} />
        <FormInput
          isDisabled={true}
          inputId={data.numberOfSamples}
          inputName={"numberOfSamples"}
          inputValue={data.numberOfSamples}
          inputPlaceholder={data.numberOfSamples}
        />
      </section>
      <section className={style.sectionContainer}>
        <CustomLabel name={"Sample origin"} color={"#525c60"} />
        <FormInput
          isDisabled={true}
          inputId={data.sampleOrigin}
          inputName={"sampleOrigin"}
          inputValue={data.sampleOrigin}
          inputPlaceholder={data.sampleOrigin}
        />
      </section>
    </Flex>
  );
};

const SampleWithType = ({ data }) => {
  return (
    <Flex alignItems={"center"} style={{ gap: 10 }}>
      <Flex style={{ width: 110 }}>
        <FormCustomCheckbox
          inputName={"sample"}
          inputValue={data.type}
          content={data.type}
          disabled={true}
          defaultChecked={true}
        />
      </Flex>
      <Flex gap={2.5}>
        <FormInput
          inputName={"numberOfSamples"}
          inputValue={data.numberOfSamples}
          inputPlaceholder={data.numberOfSamples}
        />
        <FormInput
          inputName={"sampleOrigin"}
          inputValue={data.sampleOrigin}
          inputPlaceholder={data.sampleOrigin}
        />
      </Flex>
    </Flex>
  );
};

export default function Samples({ data }) {
  return (
    <Flex flexDirection={"column"} width={"100%"}>
      <h3 className={style.title}>Samples</h3>
      {data.samples && data.samples[0] && !data.samples[0].type
        ? data.samples.map((sample, index) => (
            <SampleWithoutType data={sample} key={index} />
          ))
        : data.samples.map((sample, index) => (
            <SampleWithType data={sample} key={index} />
          ))}
      <section className={style.sectionContainer}>
        <CustomLabel name={"Direct URL to your samples"} color={"#525C60"} />
        {data.samples.map((sample, index) => {
          if (sample.link !== "-") {
            return (
              <a href={sample.link} target='_blank'>
                <p style={{ color: "#0AAA9D" }}>{sample.link}</p>
              </a>
            );
          }
        })}
      </section>
    </Flex>
  );
}
