import axios from "axios";
import { server } from "constants";

let axiosInstance;

axiosInstance = axios.create({
  baseURL: server,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem("jwt");

    if (jwtToken) {
      config.headers["Authorization"] = `Bearer ${jwtToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        console.log("401. Authentication error");
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
