import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom";
// actions
import { getUser } from "redux/actions/user";
// components
import { Flex } from "@chakra-ui/react";
import Header from "./sections/Header/Header";
import UserInformation from "./sections/UserInformation/UserInformation";
import Loader from "components/loader/Loader";
import CompanyInformation from "./sections/CompanyInformation/CompanyInformation";
import ProjectInformation from "./sections/ProjectInformation/ProjectInformation";

export default function User() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  let params = useParams();
  const currentUser = useSelector((state) => state.user.userInfo);

  useEffect(() => {
    dispatch(getUser(params.id))
      .then(function () {
        setIsLoading(false);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [params.id]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Flex flexDirection={"column"} gap={"20px"}>
      <Header />
      <UserInformation data={currentUser} />
      <CompanyInformation data={currentUser.companies}/>
      <ProjectInformation data={currentUser.projects}/>
    </Flex>
  );
}
