import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// actions
import { authGoogleAccount } from "redux/actions/user";
// components
import { Flex } from "@chakra-ui/react";
import ConfirmAction from "components/modal/ConfirmAction/ConfirmAction";
// utils
import { useModal } from "hooks/useModal";

export default function GoogleRegister({ isMobile, setGoogleData }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpen, openModal, closeModal, setIsOpen] = useModal(false);

  const handleError = (error) => {
    console.log(error);
  };

  const handleSuccess = (credentials) => {
    dispatch(authGoogleAccount(credentials.credential)).then(function (res) {
      if (res && res.name === "AxiosError") {
        setIsOpen(true);
      } else {
        setGoogleData({
          name: res.data.name,
          email: res.data.email,
          phone: "",
          password: "",
        });
      }
    });
  };

  const loginRedirect = () => {
    history.push("/auth/login");
  };

  return (
    <Flex
      justifyContent={"center"}
      style={{ marginBottom: 15, width: "100%", marginTop: 30 }}
    >
      <GoogleLogin
        onError={handleError}
        onSuccess={handleSuccess}
        shape="circle"
        theme="filled_white"
        useOneTap={true}
        width={isMobile ? 260 : 400}
        locale="en"
        text="continue_with"
        type="standard"
        context="signup"
      />
      <ConfirmAction
        isOpen={isOpen}
        closeModal={closeModal}
        width={isMobile ? 330 : 400}
        height={200}
        action={"Error"}
        question={
          "This email is already register in our database. Try with another account, or login!"
        }
        cancelFunction={closeModal}
        cancelText={"Close"}
        confirmFunction={() => loginRedirect()}
        confirmText={"Login"}
      />
    </Flex>
  );
}
