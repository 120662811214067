const columnsDataDevelopment = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Created",
    accessor: "createTime",
  },
  {
    Header: "Project name",
    accessor: "project.title",
  },
  {
    Header: "Company",
    accessor: "project.company.name",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Actions",
    accessor: "actions",
    disableSortBy: true,
  },
];

export default columnsDataDevelopment;
