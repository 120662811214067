import React from "react";
// components
import { Button, Flex } from "@chakra-ui/react";
import Modal from "components/modal/Modal";

export default function ConfirmAction({
  isOpen,
  closeModal,
  width,
  height,
  action,
  question,
  cancelFunction,
  cancelText,
  confirmFunction,
  confirmText,
}) {
  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      width={width}
      height={height}
    >
      <Flex
        flexDirection={"column"}
        width={"90%"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={5}
      >
        <Flex flexDirection={"column"} alignItems={"center"} gap={1.5}>
          <h2
            style={{
              color: "#0AAA9D",
              fontWeight: 600,
              fontSize: 22,
              textTransform: "uppercase",
            }}
          >
            {action}
          </h2>
          <p style={{textAlign: "center"}}>{question}</p>
        </Flex>
        <Flex justifyContent={"flex-end"} gap={5}>
          <Button onClick={cancelFunction}>{cancelText}</Button>
          <Button variant="brand" type="submit" onClick={confirmFunction}>
            {confirmText}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
}
