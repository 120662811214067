import React from "react";
import AuthLayout from "layouts/auth";
// components
import { Flex } from "@chakra-ui/react";
import AuthHeader from "components/authHeader/AuthHeader";
import ChangePasswordForm from "./sections/ChangePasswordForm";
// utils
import { useResponsive } from "hooks/useResponsive";

export default function ChangePassword() {
  const isMobile = useResponsive();

  return (
    <AuthLayout>
      <Flex
        borderRadius={10}
        padding={10}
        backgroundColor={"white"}
        flexDirection="column"
      >
        <AuthHeader
          title={"Change your password"}
          caption={"Enter your new credentials"}
          isMobile={isMobile}
        />
        <ChangePasswordForm isMobile={isMobile} />
      </Flex>
    </AuthLayout>
  );
}
