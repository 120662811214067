import axiosInstance from "constants/axiosInstance";
import {
  CREATE_NEW_SAMPLE,
  DELETE_SAMPLE,
  GET_SAMPLES_BY_PROJECT_ID,
} from "../actionTypes";

export function createNewSample(data) {
  return async (dispatch) => {
    try {
      const newSample = await axiosInstance.post(`samples`, data);
      dispatch({
        type: CREATE_NEW_SAMPLE,
      });
      return newSample;
    } catch (error) {
      return error;
    }
  };
}

export function getSamplesByProject(projectId) {
  return async (dispatch) => {
    try {
      const samples = await axiosInstance.get(`samples/project/${projectId}`);
      dispatch({
        type: GET_SAMPLES_BY_PROJECT_ID,
        payload: samples.data,
      });
      return samples;
    } catch (error) {
      return error;
    }
  };
}

export function deleteSample(sampleId) {
  return async (dispatch) => {
    try {
      const deleted = await axiosInstance.delete(`samples/${sampleId}`);
      dispatch({
        type: DELETE_SAMPLE,
      });
      return deleted;
    } catch (error) {
      return error;
    }
  };
}
