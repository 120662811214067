import React from "react";
// components
import { Flex } from "@chakra-ui/react";
import GreenButton from "components/buttons/GreenButton";
import CreateProjectModal from "./components/CreateProjectModal";
// utils
import { useModal } from "hooks/useModal";

export default function CreateProject({ userCompanies, userId, isMobile }) {
  const [isOpen, openModal, closeModal, setIsOpen] = useModal(false);

  return (
    <>
      <Flex justifyContent={"flex-end"}>
        <GreenButton text="+ New Project" onClick={() => setIsOpen(true)} />
      </Flex>
      <CreateProjectModal
        isOpen={isOpen}
        closeModal={closeModal}
        isMobile={isMobile}
        userCompanies={userCompanies}
        userId={userId}
      />
    </>
  );
}
