import React from "react";
import style from "../../styles/companyInformation.module.css";
// components
import { Box, Flex } from "@chakra-ui/react";
import Card from "components/card/Card";
import BoxError from "components/boxError/BoxError";
import ProjectsTable from "../../components/ProjectsTable/ProjectsTable";
// variables
import columnsDataProjects from "../../variables/columnsDataProjects";

export default function ProjectInformation({ data }) {
  return (
    <Card>
      <Flex flexDirection={"column"}>
        <Box bg="white" w="100%" p={4} color="black" borderRadius={20}>
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <h2 className={style.sectionTitle}>Projects</h2>
          </Flex>
        </Box>
        {data && data[0] !== undefined ? (
          <ProjectsTable columnsData={columnsDataProjects} tableData={data} />
        ) : (
          <BoxError message={"There aren't projects yet."} />
        )}
      </Flex>
    </Card>
  );
}
