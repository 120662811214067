import { Flex } from "@chakra-ui/react";

export const FooterLogin = ({ isMobile }) => {
  return (
    <Flex
      style={{
        backgroundColor: "#464646",
        height: isMobile ? 150 : 226,
        flexDirection: "column",
        justifyContent: "space-around",
        paddingTop: isMobile ? 15 : 20,
        marginTop: isMobile ? 10 : null,
      }}
    >
      <Flex
        style={{
          width: "100%",
          justifyContent: "space-around",
        }}
      >
        <img
          src={
            "https://res.cloudinary.com/dsxfufacm/image/upload/v1692723759/Taxon/white-logo-taxon-descriptivo_iqzobs.svg"
          }
          alt="logo-taxon"
          style={{ width: isMobile ? 90 : 150 }}
          
        />
        <Flex
          style={{
            alignItems: "center",
            gap: 10,
          }}
        >
          <a
            href="https://www.linkedin.com/company/taxon-tech/"
            target="_blank"
            style={{ zIndex: 1, cursor: "pointer" }}
          >
            <img
              src={
                "https://res.cloudinary.com/dsxfufacm/image/upload/v1692723757/Taxon/logo-linkedn_jnfjvu.svg"
              }
              alt="logo-linkedn"
              style={{ width: isMobile ? 30 : 45 }}
            />
          </a>
          <Flex
            style={{
              flexDirection: "column",
            }}
          >
            <h3
              style={{
                fontSize: isMobile ? 12 : 16,
                fontWeight: 700,
                color: "white",
              }}
            >
              Contact us
            </h3>
            <p
              style={{
                fontSize: isMobile ? 12 : 14,
                fontWeight: 400,
                color: "white",
              }}
            >
              info@taxon.com
            </p>
          </Flex>
        </Flex>
      </Flex>
      <Flex direction={"column"} align={"center"} gap={isMobile ? 5 : 10}>
        <hr
          style={{
            backgroundColor: "white",
            height: 1,
            width: "100%",
            border: 0,
          }}
        />
        <p
          style={{
            fontSize: isMobile ? 12 : 16,
            fontWeight: 400,
            color: "white",
          }}
        >
          Copyright © 2023 - Desarrollado por <b>Delsud</b>
        </p>
      </Flex>
    </Flex>
  );
};
