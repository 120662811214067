import React, { useMemo } from "react";
// components
import { Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import Card from "components/card/Card";
import QuotationsActions from "./actions/QuotationsActions";
// table manage
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

export default function QuotationTable(props) {
  const { columnsData, tableData } = props;

  const tableDataWithActions = useMemo(() => {
    return tableData.map((row) => ({
      ...row,
      actions: {
        id: row.id,
      },
    }));
  }, [tableData]);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableDataWithActions, [tableData]);

  const tableInstance = useTable(
    { columns, data },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 50;

  return (
    <Card direction="column" w="100%" overflowX={"auto"}>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead backgroundColor={"#F0FAF9"}>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                >
                  <Flex
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color={"#525C60"}
                    style={{ minWidth: 187 }}
                  >
                    <h6>{column.Header}</h6>
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr
                {...row.getRowProps()}
                key={index}
                _hover={{ backgroundColor: "#F4F4F4" }}
              >
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "ID") {
                    data = (
                      <Text fontSize="sm" fontWeight="700">
                        # {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "Created") {
                    data = (
                      <Text fontSize="sm" fontWeight="700">
                        {cell.value.slice(0, 10)}
                      </Text>
                    );
                  } else if (cell.column.Header === "Status") {
                    data = (
                      <Flex
                        width={130}
                        justify={"center"}
                        padding={0.5}
                        borderRadius={15}
                        backgroundColor={
                          cell.value === "ready" ? "#5CB85C" : "#F0AD4E"
                        }
                        color={"#fff"}
                      >
                        {cell.value}
                      </Flex>
                    );
                  } else if (cell.column.Header === "Actions") {
                    data = <QuotationsActions id={cell.value.id} />;
                  } else {
                    data = (
                      <Text fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor="transparent"
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}
