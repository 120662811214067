import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
// actions
import { createNewSample } from "redux/actions/samples";
import { createNewProject } from "redux/actions/project";
import { getProjects } from "redux/actions/project";
// components
import { Button, Flex } from "@chakra-ui/react";
import Modal from "components/modal/Modal";
import FormInput from "components/inputs/FormInput/FormInput";
import ErrorText from "components/errorText/ErrorText";
import CustomLabel from "components/customLabel/CustomLabel";
// utils
import { useForm } from "hooks/useForm";
import { createNewCompany } from "redux/actions/company";
import { getUserCompanies } from "redux/actions/company";

export default function CreateCompany({
  isOpen,
  closeModal,
  setIsOpen,
  isMobile,
  user,
}) {
  const dispatch = useDispatch();

  const initialForm = {
    name: "",
    position: "",
    department: "",
    street: "",
    city: "",
    zipcode: "",
  };

  const validateForm = (form) => {
    let errors = {};

    if (form.name === "") {
      errors.name = "Empty field";
    }
    if (form.position === "") {
      errors.position = "Empty field";
    }
    if (form.department === "") {
      errors.department = "Empty field";
    }
    if (form.city === "") {
      errors.city = "Empty field";
    }
    if (form.zipcode === "") {
      errors.zipcode = "Empty field";
    }

    return errors;
  };

  const {
    form,
    errors,
    response,
    send,
    handleChange,
    handleSubmit,
    resetForm,
  } = useForm(initialForm, validateForm);

  useEffect(() => {
    if (response) {
      dispatch(
        createNewCompany({
          ...form,
          user: { id: user.id },
        })
      )
        .then(function () {
          dispatch(getUserCompanies(user.id))
            .then(function () {
              return;
            })
            .catch(function (err) {
              console.log(err);
            });
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }, [response]);

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      width={isMobile ? 320 : 518}
      height={isMobile ? 560 : 540}
    >
      <Flex flexDirection={"column"} width={"90%"}>
        <Flex
          className="title"
          style={{
            textTransform: "uppercase",
          }}
        >
          <h2 style={{ color: "#0AAA9D", fontSize: 18, fontWeight: 600 }}>
            Add company
          </h2>
        </Flex>
        <hr style={{ marginTop: 10, marginBottom: 10 }} />
        <form onSubmit={handleSubmit}>
          <section style={{ width: "100%" }}>
            <CustomLabel
              color={"#525c60"}
              highlightColor={"#0aaa9d"}
              name={"Company name"}
            />
            <FormInput
              inputId={"name"}
              inputName={"name"}
              inputValue={form.name}
              inputPlaceholder={""}
              handleChange={handleChange}
            />
            {send && errors.name && <ErrorText err={errors.name} />}
          </section>
          <Flex gap={2.5}>
            <section style={{ width: "100%" }}>
              <CustomLabel
                color={"#525c60"}
                highlightColor={"#0aaa9d"}
                name={"Position"}
              />
              <FormInput
                inputId={"position"}
                inputName={"position"}
                inputValue={form.position}
                inputPlaceholder={""}
                handleChange={handleChange}
              />
              {send && errors.position && <ErrorText err={errors.position} />}
            </section>
            <section style={{ width: "100%" }}>
              <CustomLabel
                color={"#525c60"}
                highlightColor={"#0aaa9d"}
                name={"Department"}
              />
              <FormInput
                inputId={"department"}
                inputName={"department"}
                inputValue={form.department}
                inputPlaceholder={""}
                handleChange={handleChange}
              />
              {send && errors.department && (
                <ErrorText err={errors.department} />
              )}
            </section>
          </Flex>
          <section style={{ width: "100%" }}>
            <CustomLabel color={"#525c60"} name={"Street"} />
            <FormInput
              inputId={"street"}
              inputName={"street"}
              inputValue={form.street}
              inputPlaceholder={""}
              handleChange={handleChange}
            />
          </section>
          <Flex gap={2.5}>
            <section style={{ width: "100%" }}>
              <CustomLabel
                color={"#525c60"}
                highlightColor={"#0aaa9d"}
                name={"City"}
              />
              <FormInput
                inputId={"city"}
                inputName={"city"}
                inputValue={form.city}
                inputPlaceholder={""}
                handleChange={handleChange}
              />
              {send && errors.city && <ErrorText err={errors.city} />}
            </section>
            <section style={{ width: "100%" }}>
              <CustomLabel
                color={"#525c60"}
                highlightColor={"#0aaa9d"}
                name={"Zipcode"}
              />
              <FormInput
                inputId={"zipcode"}
                inputName={"zipcode"}
                inputValue={form.zipcode}
                inputPlaceholder={""}
                handleChange={handleChange}
              />
              {send && errors.zipcode && <ErrorText err={errors.zipcode} />}
            </section>
          </Flex>
          <Flex justifyContent={"flex-end"} gap={5} mb={5}>
            <Button onClick={closeModal}>Cancel</Button>
            <Button variant="brand" type="submit" onClick={handleSubmit}>
              Create
            </Button>
          </Flex>
        </form>
      </Flex>
    </Modal>
  );
}
