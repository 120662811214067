import React from "react";
// components
import { Button } from "@chakra-ui/react";

export default function GrayButton({ text, onClick }) {
  return (
    <Button
      variant="brand"
      onClick={onClick}
      style={{
        backgroundColor: "#F4F7FE",
        color: "#525C60",
      }}
    >
      {text}
    </Button>
  );
}
