import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
// actions
import { logIn } from "redux/actions/user";
// components
import { Button, Flex, Link } from "@chakra-ui/react";
import CustomLabel from "components/customLabel/CustomLabel";
import FormInput from "components/inputs/FormInput/FormInput";
import GoogleAuth from "../../components/GoogleLogin/GoogleAuth";
import KeepMeLogged from "../../components/KeepMeLogged/KeepMeLogged";
import ForgotPassword from "../../components/ForgotPassword/ForgotPassword";
import InputPassword from "components/inputs/InputPassword/InputPassword";
import ErrorText from "components/errorText/ErrorText";
import Message from "components/modal/Message/Message";
// utils
import { useForm } from "hooks/useForm";
import { useModal } from "hooks/useModal";

export default function LoginForm({ isMobile }) {
  const dispatch = useDispatch();
  const [isOpen, openModal, closeModal, setIsOpen] = useModal(false);
  const [isOpenErr403, openModalErr403, closeModalErr403, setIsOpenErr403] =
    useModal(false);
  const [isOpenErr404, openModalErr404, closeModalErr404, setIsOpenErr404] =
    useModal(false);

  const initialForm = {
    email: "",
    password: "",
  };

  const validationForm = (form) => {
    let errors = {};
    let regexEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!form.email.trim()) {
      errors.email = "Empty field";
    } else if (!regexEmail.test(form.email.trim())) {
      errors.email = "The email format isn't correct";
    }

    if (!form.password.trim()) {
      errors.password = "Empty field";
    }

    return errors;
  };

  const {
    form,
    errors,
    response,
    send,
    handleChange,
    handleSubmit,
    resetForm,
  } = useForm(initialForm, validationForm);

  useEffect(() => {
    if (response) {
      dispatch(logIn(form))
        .then(function (res) {
          if (res && res.name === "AxiosError") {
            if (
              res.response &&
              res.response.status &&
              res.response.status === 403
            ) {
              resetForm();
              setIsOpenErr403(true);
            } else if (
              res.response &&
              res.response.status &&
              res.response.status === 404
            ) {
              resetForm();
              setIsOpenErr404(true);
            } else {
              resetForm();
              setIsOpen(true);
            }
          }
          // success case
          resetForm();
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }, [response]);

  return (
    <>
      <form>
        <section style={{ width: "100%" }}>
          <CustomLabel
            color={"#525c60"}
            highlightColor={"#0aaa9d"}
            name={"Email"}
          />
          <FormInput
            inputId={"email"}
            inputName={"email"}
            inputValue={form.email}
            inputPlaceholder={"For example: mail@example.com"}
            handleChange={handleChange}
          />
          {send && errors.email && <ErrorText err={errors.email} />}
        </section>
        <section style={{ width: "100%" }}>
          <CustomLabel
            color={"#525c60"}
            highlightColor={"#0aaa9d"}
            name={"Password"}
          />
          <InputPassword
            iconColor={"#525c6060"}
            inputId={"password"}
            inputName={"password"}
            inputValue={form.password}
            inputPlaceholder={"Min. 8 characters"}
            handleChange={handleChange}
          />
          {send && errors.password && <ErrorText err={errors.password} />}
        </section>
        <Flex justifyContent={"space-between"}>
          <KeepMeLogged />
          <ForgotPassword isMobile={isMobile} />
        </Flex>
        <Button
          variant="brand"
          onClick={handleSubmit}
          width={"100%"}
          mt={5}
          fontWeight="500"
          fontSize={"sm"}
        >
          Sign In
        </Button>
        <GoogleAuth isMobile={isMobile} />
        <Flex fontSize="sm">
          <p>
            Not registered yet?{" "}
            <Link
              href="#/auth/register"
              style={{ color: "#0AAA9D", fontWeight: 500, cursor: "pointer" }}
            >
              Create an account
            </Link>
          </p>
        </Flex>
      </form>
      <Message
        isOpen={isOpenErr403}
        closeModal={closeModalErr403}
        setIsOpen={setIsOpenErr403}
        width={isMobile ? 200 : 400}
        height={isMobile ? 100 : 200}
        title={"Error"}
        caption={"Invalid password."}
        buttonFunction={closeModalErr403}
        buttonText={"Try again"}
      />
      <Message
        isOpen={isOpenErr404}
        closeModal={closeModalErr404}
        setIsOpen={setIsOpenErr404}
        width={isMobile ? 200 : 400}
        height={isMobile ? 100 : 200}
        title={"Error"}
        caption={
          "This email isn't register in our database. Try with another user, or register."
        }
        buttonFunction={closeModalErr404}
        buttonText={"Close"}
      />
      <Message
        isOpen={isOpen}
        closeModal={closeModal}
        setIsOpen={setIsOpen}
        width={isMobile ? 200 : 400}
        height={isMobile ? 100 : 200}
        title={"Error"}
        caption={"An unexpected error occurred."}
        buttonFunction={closeModal}
        buttonText={"Try again"}
      />
    </>
  );
}
