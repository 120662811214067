import React from "react";
import style from "../../styles/createQuotation.module.css";
// components
import { Flex } from "@chakra-ui/react";
import GreenButton from "components/buttons/GreenButton";
import CreateQuotationModal from "./components/CreateQuotationModal";
// utils
import { useModal } from "hooks/useModal";

export default function CreateQuotation({ user, userCompanies, userProjects }) {
  const [isOpen, openModal, closeModal, setIsOpen] = useModal(false);

  return (
    <>
      <Flex justifyContent={"space-between"} alignItems={"flex-end"}>
        <Flex className={style.title}>
          <h2>Quotations</h2>
          <p>
            In this chart you will find the requested quotations. In addition to
            seeing the status, you will be able to have a preview and download
            the document once it's ready. After downloading it, you can chat
            with our team to accept it or make inquiries, and continue with the
            process.
          </p>
        </Flex>
        <GreenButton text="+ New Quotation" onClick={() => setIsOpen(true)} />
      </Flex>
      <CreateQuotationModal
        isOpen={isOpen}
        closeModal={closeModal}
        isMobile={false}
        user={user}
        userCompanies={userCompanies}
        userProjects={userProjects}
      />
    </>
  );
}
