import React from "react";
// components
import { Flex } from "@chakra-ui/react";
import CloseButton from "./buttons/CloseButton";
import QuoteButton from "./buttons/QuoteButton";

export default function AdminCase({
  data,
  closeFunction,
  quoteFunction,
  onlySee,
}) {
  return (
    <Flex
      justifyContent={"flex-end"}
      gap={2.5}
      style={{ marginTop: 10, paddingBottom: 20 }}
    >
      <CloseButton closeFunction={closeFunction} />
      {data.status !== "ready" && !onlySee && (
        <QuoteButton quoteFunction={quoteFunction} />
      )}
    </Flex>
  );
}
