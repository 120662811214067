export const columnsDataCompanies = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Created",
    accessor: "createTime",
  },
];

export default columnsDataCompanies;
