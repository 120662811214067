import React from "react";
// assets
import eyeIcon from "../../../../../../assets/img/icons/eye.svg";
// components
import { Flex, Link } from "@chakra-ui/react";

export default function DetailButton({ id }) {
  return (
    <Flex style={{ cursor: "pointer" }}>
      <Link href={`#/client/project/${id}`}>
        <img src={eyeIcon} alt="See detail - Icon" />
      </Link>
    </Flex>
  );
}
