import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// actions
import { createNewResult } from "redux/actions/results";
// components
import { Button, Flex } from "@chakra-ui/react";
import Modal from "components/modal/Modal";
import CustomLabel from "components/customLabel/CustomLabel";
import FormInput from "components/inputs/FormInput/FormInput";
import ErrorText from "components/errorText/ErrorText";
// utils
import { useForm } from "hooks/useForm";
import { updateOrder } from "redux/actions/quotation";
import { getAllOrders } from "redux/actions/quotation";

export default function CreateResult({
  isOpen,
  closeModal,
  setIsOpen,
  isMobile,
  quotationId,
}) {
  const dispatch = useDispatch();

  const initialForm = {
    description: "",
    link: "",
  };

  const validateForm = (form) => {
    let errors = {};
    if (form.description.trim() === "") {
      errors.description = "Empty field";
    }
    if (form.link.trim() === "") {
      errors.link = "Empty field";
    }

    return errors;
  };

  const {
    form,
    errors,
    response,
    send,
    handleChange,
    handleSubmit,
    resetForm,
  } = useForm(initialForm, validateForm);

  useEffect(() => {
    if (response) {
      dispatch(
        createNewResult({
          ...form,
          quotation: { id: quotationId },
        })
      ).then(function () {
        dispatch(updateOrder(quotationId))
          .then(function () {
            resetForm();
            setIsOpen(false);
            dispatch(getAllOrders());
          })
          .catch(function (err) {
            console.log(err);
          });
      });
    }
  }, [response]);

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      width={isMobile ? 330 : 513}
      height={isMobile ? 330 : 325}
    >
      <Flex flexDirection={"column"} width={"90%"}>
        <Flex
          className="title"
          style={{
            textTransform: "uppercase",
          }}
        >
          <h2 style={{ color: "#0AAA9D", fontSize: 18, fontWeight: 600 }}>
            Add result
          </h2>
        </Flex>
        <hr style={{ marginTop: 10, marginBottom: 10 }} />
        <form>
          <section>
            <CustomLabel color={"#525c60"} name={"Results name"} />
            <FormInput
              inputId={"resultTitle"}
              inputName={"description"}
              inputValue={form.description}
              inputPlaceholder={""}
              handleChange={handleChange}
            />
            {send && errors.description && (
              <ErrorText err={errors.description} />
            )}
          </section>
          <section>
            <CustomLabel color={"#525c60"} name={"URL to results"} />
            <FormInput
              inputId={"resultLink"}
              inputName={"link"}
              inputValue={form.link}
              inputPlaceholder={""}
              handleChange={handleChange}
            />
            {send && errors.link && <ErrorText err={errors.link} />}
          </section>
          <Flex justifyContent={"flex-end"} gap={5} mb={5}>
            <Button onClick={closeModal}>Cancel</Button>
            <Button variant="brand" type="submit" onClick={handleSubmit}>
              Upload
            </Button>
          </Flex>
        </form>
      </Flex>
    </Modal>
  );
}
