import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import style from "../../styles/budgetResume.module.css";
// actions
import { getQuotation } from "redux/actions/quotation";
// components
import Modal from "components/modal/Modal";
import { Flex } from "@chakra-ui/react";
import { FormInputItem } from "components/formItems/FormItems";
import FormCustomCheckbox from "components/inputs/FormCustomCheckbox/FormCustomCheckbox";
import GreenButton from "components/buttons/GreenButton";

const Item = ({ data }) => {
  return (
    <Flex
      style={{
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Flex style={{ width: 200 }}>
        <FormCustomCheckbox
          inputName={"service"}
          inputValue={data.name}
          content={data.name}
          disabled={true}
          defaultChecked={true}
        />
      </Flex>
      <Flex style={{ gap: 15, width: 350 }}>
        <Flex style={{ width: 150 }}>
          <FormInputItem
            label={""}
            name={"quantity"}
            value={data.quantity}
            placeholder={data.quantity}
            isDisabled={true}
          />
        </Flex>
        <Flex style={{ width: 200 }}>
          <Flex alignItems={"center"} style={{ height: 64 }}>
            <div
              style={{
                backgroundColor: "#CED4DA",
                height: 48,
                width: 60,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderTopLeftRadius: 5,
                borderBottomLeftRadius: 5,
              }}
            >
              <p>u$s</p>
            </div>
          </Flex>
          <FormInputItem
            label={""}
            name={"price"}
            value={data.price}
            placeholder={data.price}
            isDisabled={true}
            styles={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default function BudgetResume({
  id,
  isOpen,
  closeModal,
  isMobile,
  confirmFunction,
}) {
  const dispatch = useDispatch();
  const [quotation, setQuotation] = useState(null);

  useEffect(() => {
    dispatch(getQuotation(id))
      .then(function (res) {
        setQuotation(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [id]);

  if (!quotation) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      width={isMobile ? 330 : 700}
      height={"auto"}
    >
      <Flex flexDirection={"column"} width={"90%"}>
        <Flex style={{ textTransform: "uppercase" }}>
          <h2 style={{ color: "#0AAA9D", fontSize: 18 }}>
            Quotation Summary - #{quotation.id}
          </h2>
        </Flex>
        <hr style={{ marginTop: 10, marginBottom: 10 }} />
        <Flex
          style={{
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 10,
          }}
        >
          <Flex style={{ width: 200 }}>
            <h3 className={style.sectionName} style={{ paddingBottom: 10 }}>
              Services
            </h3>
          </Flex>
          <Flex style={{ width: 350, gap: 15 }}>
            <Flex
              style={{
                width: 150,
              }}
            >
              <h3 className={style.sectionName} style={{ paddingBottom: 10 }}>
                Quantity
              </h3>
            </Flex>
            <Flex
              style={{
                width: 200,
              }}
            >
              <h3 className={style.sectionName} style={{ paddingBottom: 10 }}>
                Price
              </h3>
            </Flex>
          </Flex>
        </Flex>
        {quotation.quotationsolutions[0].service.map((service) => (
          <Item data={service} />
        ))}
        <Flex justifyContent={'flex-end'}>
          <GreenButton text={"Accept"} onClick={() => confirmFunction()} />
        </Flex>
      </Flex>
    </Modal>
  );
}
