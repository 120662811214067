import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// components
import Loader from "components/loader/Loader";
import ViewContainer from "components/container/ViewContainer";
import CreateProject from "./sections/CreateProject/CreateProject";
import TableContainer from "./sections/TableContainer";
// utils
import { useResponsive } from "hooks/useResponsive";
import useUserCompanies from "hooks/useUserCompanies";
import useUserProjects from "hooks/useUserProjects";

export default function UserProjects() {
  const user = useSelector((state) => state.user.currentUser);
  const isMobile = useResponsive();
  const { companies, isLoading: companiesLoading } = useUserCompanies(user.id);
  const { projects, isLoading: projectsLoading } = useUserProjects(user.id);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(companiesLoading || projectsLoading);
  }, [companiesLoading, projectsLoading]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ViewContainer>
      <CreateProject
        userCompanies={companies}
        userId={user.id}
        isMobile={isMobile}
      />
      <TableContainer projects={projects} isMobile={isMobile} />
    </ViewContainer>
  );
}
