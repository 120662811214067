import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
// actions
import { getProject } from "redux/actions/project";
// assets
import editIcon from "../../../../../../assets/img/icons/edit.svg";
// components
import { Flex } from "@chakra-ui/react";
import EditProject from "components/modal/EditProject/EditProject";
// utils
import { useModal } from "hooks/useModal";

export default function EditButton({ id, isMobile }) {
  const [isOpen, openModal, closeModal, setIsOpen] = useModal(false);

  // obtain project data
  const dispatch = useDispatch();
  const [projectData, setProjectData] = useState(null);
  useEffect(() => {
    dispatch(getProject(id)).then(function (res) {
      setProjectData(res);
    });
  }, [id]);

  return (
    <Flex style={{ cursor: "pointer" }}>
      <div onClick={() => setIsOpen(true)}>
        <img src={editIcon} alt="Edit project - Icon" />
      </div>
      {projectData && (
        <EditProject
          id={id}
          data={projectData}
          isOpen={isOpen}
          closeModal={closeModal}
          setIsOpen={setIsOpen}
          isMobile={isMobile}
        />
      )}
    </Flex>
  );
}
