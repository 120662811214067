import { UPDATE_SERVICE } from "redux/actions/actionTypes";
import { UPDATE_ORDER } from "redux/actions/actionTypes";
import {
  CREATE_NEW_QUOTATION,
  DELETE_QUOTATION,
  GET_ALL_QUOTATIONS,
  GET_ALL_ORDERS,
  GET_ORDERS_BY_USER_ID,
  GET_ORDERS_BY_PROJECT_ID,
  GET_QUOTATION,
  GET_QUOTATIONS_BY_PROJECT_ID,
  GET_QUOTATIONS_BY_USER_ID,
  UPDATE_QUOTATION,
  UPDATE_QUOTATION_SOLUTION,
} from "redux/actions/actionTypes";

const initialState = {
  allQuotations: null,
  allOrders: null,
  userQuotations: null,
  userOrders: null,
  projectQuotations: null,
  projectOrders: null,
};

const quotationReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NEW_QUOTATION:
      return {
        ...state,
      };
    case GET_ALL_QUOTATIONS:
      return {
        ...state,
        allQuotations: action.payload,
      };
    case GET_ALL_ORDERS:
      return {
        ...state,
        allOrders: action.payload,
      };
    case GET_QUOTATIONS_BY_USER_ID:
      return {
        ...state,
        userQuotations: action.payload,
      };
    case GET_QUOTATIONS_BY_PROJECT_ID:
      return {
        ...state,
        projectQuotations: action.payload,
      };
    case GET_ORDERS_BY_USER_ID:
      return {
        ...state,
        userOrders: action.payload,
      };
    case GET_ORDERS_BY_PROJECT_ID:
      return {
        ...state,
        projectOrders: action.payload,
      };
    case GET_QUOTATION:
      return {
        ...state,
      };
    case DELETE_QUOTATION:
      return {
        ...state,
      };
    case UPDATE_QUOTATION:
      return {
        ...state,
      };
    case UPDATE_SERVICE:
      return {
        ...state,
      };
    case UPDATE_ORDER:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default quotationReducer;
