import { combineReducers } from "redux";
import userReducer from "./user";
import projectReducer from "./project";
import quotationReducer from "./quotation";
import companyReducer from "./company";
import sampleReducer from "./samples";
import resultReducer from "./results";
import messagesReducer from "./messages";

const rootReducer = combineReducers({
  user: userReducer,
  project: projectReducer,
  quotation: quotationReducer,
  company: companyReducer,
  sample: sampleReducer,
  result: resultReducer,
  message: messagesReducer,
});

export default rootReducer;
