import React, { useEffect, useState } from "react";
import style from "../../../../../styles/form.module.css";
// components
import { Flex } from "@chakra-ui/react";
import { FormCheckboxItem } from "components/formItems/FormItems";
import GreenButton from "components/buttons/GreenButton";

export default function SolutionSection({
  form,
  handleChange,
  changeView,
  closeModal,
  errors,
}) {
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    if (errors.solution) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [errors]);

  return (
    <Flex className={style.formContainer}>
      <form className={style.form}>
        <div>
          <FormCheckboxItem
            name={"solution"}
            value={1}
            content={"Microbial Communities (Metagenomics)"}
            description={
              "I want to identify the microorganisms present in my sample."
            }
            handleChange={handleChange}
            checked={form.solution === '1' ? true : false}
          />
          <FormCheckboxItem
            name={"solution"}
            value={2}
            content={"Single Strains"}
            description={
              "I want to know the identity and characterize isolated microorganisms"
            }
            handleChange={handleChange}
            checked={form.solution === '2' ? true : false}
          />
          <FormCheckboxItem
            name={"solution"}
            value={3}
            content={"Other - Customized Project"}
            description={
              "If you find yourself uncertain, we recommend selecting a customized project. Our team will provide you with expert assistance throughout the entire process, completely free of charge."
            }
            handleChange={handleChange}
            checked={form.solution === '3' ? true : false}
          />
        </div>
        <Flex className={style.buttonsContainer}>
          <div onClick={closeModal}>
            <h6>Skip</h6>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 30,
            }}
          >
            <div onClick={() => changeView(1)}>
              <h6 style={{ color: "#0AAA9D", fontWeight: 500 }}>Back</h6>
            </div>
            <GreenButton
              text={"Next"}
              onClick={() => changeView(3)}
              isDisabled={isDisabled}
            />
          </div>
        </Flex>
      </form>
    </Flex>
  );
}
