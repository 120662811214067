import React from "react";
// components
import { Flex } from "@chakra-ui/react";
import UnableRedirectButton from "../buttons/UnableRedirectButton";
import ChatButton from "../buttons/ChatButton";

export default function InProgressActions() {
  return (
    <Flex gap={10} alignItems={"center"}>
      <UnableRedirectButton />
      <ChatButton />
    </Flex>
  );
}
