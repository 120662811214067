import React, { useState } from "react";
import style from "../../../styles/createQuotation.module.css";
// components
import { Flex } from "@chakra-ui/react";
import Modal from "components/modal/Modal";
import Progress from "./Progress";
import Form from "./Form/Form";

export default function CreateQuotationModal({
  isOpen,
  closeModal,
  isMobile,
  user,
  userCompanies,
  userProjects,
}) {
  const [currentStep, setCurrentStep] = useState(1);

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      width={isMobile ? 320 : 856}
      height={isMobile ? 560 : 640}
    >
      <Flex className={style.modal}>
        <Progress currentStep={currentStep} />
        <Form
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          user={user}
          userCompanies={userCompanies}
          userProjects={userProjects}
          closeModal={closeModal}
        />
      </Flex>
    </Modal>
  );
}
