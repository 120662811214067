import axiosInstance from "constants/axiosInstance";
import {
  CREATE_NEW_PROJECT,
  GET_ALL_PROJECTS,
  GET_PROJECT,
  GET_PROJECTS_BY_USER_ID,
  UPDATE_PROJECT,
} from "../actionTypes";

export function createNewProject(data) {
  return async (dispatch) => {
    try {
      const newProject = await axiosInstance.post(`projects`, data);
      dispatch({
        type: CREATE_NEW_PROJECT,
      });
      return newProject.data;
    } catch (error) {
      return error;
    }
  };
}

export function getAllProjects() {
  return async (dispatch) => {
    try {
      const projects = await axiosInstance.get("/projects");
      dispatch({
        type: GET_ALL_PROJECTS,
        payload: projects.data,
      });
      return projects.data;
    } catch (error) {
      return error;
    }
  };
}

export function getProjects(userId) {
  return async (dispatch) => {
    try {
      let projects = await axiosInstance.get(`/projects/user/${userId}`);
      dispatch({
        type: GET_PROJECTS_BY_USER_ID,
        payload: projects.data,
      });
      return projects.data;
    } catch (error) {
      return error;
    }
  };
}

export function getProject(projectId) {
  return async (dispatch) => {
    try {
      let project = await axiosInstance.get(`/projects/${projectId}`);
      dispatch({
        type: GET_PROJECT,
        payload: project.data,
      });
      return project.data;
    } catch (error) {
      return error;
    }
  };
}

export function updateProject(projectId, data) {
  return async (dispatch) => {
    try {
      const updateFields = {};
      if (data.title) {
        updateFields.title = data.title;
      }
      if (data.details) {
        updateFields.details = data.details;
      }

      let update = await axiosInstance.patch(
        `/projects/${projectId}`,
        updateFields
      );
      dispatch({
        type: UPDATE_PROJECT,
      });
      return;
    } catch (error) {
      return error;
    }
  };
}
