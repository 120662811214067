import React from "react";
import style from "./styles/modal.module.css";

export default function Modal({ children, isOpen, closeModal, width, height }) {
  const handleModalClick = (e) => e.stopPropagation();

  return (
    <div
      className={`${style.modal} ${isOpen && style.isOpen}`}
      onClick={closeModal}
    >
      <div
        className={style.container}
        onClick={handleModalClick}
        style={{ width: width, height: height }}
      >
        {children}
      </div>
    </div>
  );
}
