import React from "react";
import style from "../../styles/newService.module.css";
// components
import { Box, Flex } from "@chakra-ui/react";
import BoxError from "components/boxError/BoxError";
import OrderTable from "../../components/OrderTable/OrderTable";
// variables
import columnsDataDevelopment from "../../variables/columnsDataOrder";

export default function Orders({ data }) {
  return (
    <Flex className={style.componentContainer}>
      <h2>Orders</h2>
      <Flex className={style.componentText}>
        <p>
          In this chart you will find the requested services after accepting the
          quotations. You will be able to download the results once they are
          ready and communicate with our team.to make inquiries if needed.
        </p>
      </Flex>
      {data && data[0] !== undefined ? (
        <Box bg="white" w="100%" p={4} color="black" borderRadius={20}>
          <OrderTable columnsData={columnsDataDevelopment} tableData={data} />
        </Box>
      ) : (
        <BoxError message={"There aren't any orders"} />
      )}
    </Flex>
  );
}
