import React from "react";
import style from "../styles/quotationDetail.module.css";
// components
import { Flex } from "@chakra-ui/react";
import CustomLabel from "components/customLabel/CustomLabel";
import FormInput from "components/inputs/FormInput/FormInput";

export default function PersonalDetails({ data }) {
  return (
    <Flex flexDirection={"column"} width={"100%"}>
      <h3 className={style.title}>Personal Details</h3>
      <Flex justifyContent={"space-between"} gap={5}>
        <section className={style.sectionContainer}>
          <CustomLabel name={"Company / Institute"} color={"#525c60"} />
          <FormInput
            isDisabled={true}
            inputId={data.project.company.name}
            inputName={"company"}
            inputValue={data.project.company.name}
            inputPlaceholder={data.project.company.name}
          />
        </section>
        <section className={style.sectionContainer}>
          <CustomLabel name={"Position"} color={"#525c60"} />
          <FormInput
            isDisabled={true}
            inputId={data.project.company.position}
            inputName={"position"}
            inputValue={data.project.company.position}
            inputPlaceholder={data.project.company.position}
          />
        </section>
      </Flex>
      <Flex>
        <section className={style.sectionContainer}>
          <CustomLabel name={"Name"} color={"#525c60"} />
          <FormInput
            isDisabled={true}
            inputId={data.project.user.name}
            inputName={"userName"}
            inputValue={data.project.user.name}
            inputPlaceholder={data.project.user.name}
          />
        </section>
      </Flex>
      <Flex justifyContent={"space-between"} gap={5}>
        <section className={style.sectionContainer}>
          <CustomLabel name={"Email"} color={"#525c60"} />
          <FormInput
            isDisabled={true}
            inputId={data.project.user.email}
            inputName={"userEmail"}
            inputValue={data.project.user.email}
            inputPlaceholder={data.project.user.email}
          />
        </section>
        <section className={style.sectionContainer}>
          <CustomLabel name={"Phone number"} color={"#525c60"} />
          <FormInput
            isDisabled={true}
            inputId={data.project.user.phone}
            inputName={"userPhone"}
            inputValue={data.project.user.phone}
            inputPlaceholder={data.project.user.phone}
          />
        </section>
      </Flex>
    </Flex>
  );
}
