import React from "react";
// components
import { Flex } from "@chakra-ui/react";
import UnableUploadButton from "../buttons/UnableUploadButton";
import RedirectButton from "../buttons/RedirectButton";

export default function ReadyActions({ data }) {
  console.log(data)
  return (
    <Flex gap={10} alignItems={"center"}>
      <UnableUploadButton />
      <RedirectButton link={data[0].link} />
    </Flex>
  );
}
