import React, { useEffect, useState } from "react";
import style from "../../../../../styles/form.module.css";
// components
import { Flex } from "@chakra-ui/react";
import {
  Customized,
  MicrobialCommunities,
  SingleStrains,
} from "./ContentBySolution";
import GreenButton from "components/buttons/GreenButton";

export default function ProjectSection({
  solutionId,
  userProjects,
  selectedProject,
  setSelectedProject,
  form,
  handleChange,
  changeView,
  closeModal,
  errors,
  handleSubmit,
}) {
  const [isDisabled, setIsDisabled] = useState(true);
  useEffect(() => {
    if (
      errors.projectName ||
      errors.projectDescription ||
      errors.numberOfSamples ||
      errors.sampleURL
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [errors]);

  return (
    <Flex className={style.formContainer}>
      <form className={style.form}>
        {solutionId === "1" ? (
          <MicrobialCommunities
            form={form}
            handleChange={handleChange}
            userProjects={userProjects}
            selectedProject={selectedProject}
            setSelectedProject={setSelectedProject}
            errors={errors}
          />
        ) : solutionId === "2" ? (
          <SingleStrains
            form={form}
            handleChange={handleChange}
            userProjects={userProjects}
            selectedProject={selectedProject}
            setSelectedProject={setSelectedProject}
            errors={errors}
          />
        ) : (
          <Customized
            form={form}
            handleChange={handleChange}
            userProjects={userProjects}
            selectedProject={selectedProject}
            setSelectedProject={setSelectedProject}
            errors={errors}
          />
        )}
        <Flex
          className={style.buttonsContainer}
          style={{ paddingBottom: solutionId === "2" && 20 }}
        >
          <div onClick={closeModal}>
            <h6>Skip</h6>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 30,
            }}
          >
            <div onClick={() => changeView(2)}>
              <h6 style={{ color: "#0AAA9D", fontWeight: 500 }}>Back</h6>
            </div>
            <GreenButton
              text={"Send"}
              onClick={handleSubmit}
              isDisabled={isDisabled}
            />
          </div>
        </Flex>
      </form>
    </Flex>
  );
}
