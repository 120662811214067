import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// actions
import { updateUserPassword } from "redux/actions/user";
// components
import { Button, Flex } from "@chakra-ui/react";
import CustomLabel from "components/customLabel/CustomLabel";
import InputPassword from "components/inputs/InputPassword/InputPassword";
import ErrorText from "components/errorText/ErrorText";
import Message from "components/modal/Message/Message";
// utils
import { useForm } from "hooks/useForm";
import { useModal } from "hooks/useModal";

export default function ChangePasswordForm({ isMobile }) {
  const dispatch = useDispatch();
  const history = useHistory();

  // Get token from params
  const url = window.location.href;
  const token = url.split("/").pop();

  // Response manage
  const [isOpen, openModal, closeModal, setIsOpen] = useModal(false);
  const [isOpenErr, openModalErr, closeModalErr, setIsOpenErr] =
    useModal(false);

  const initialForm = {
    password: "",
    confirmPassword: "",
  };

  const validationForm = (form) => {
    let errors = {};

    if (form.password === "") {
      errors.password = "Empty field";
    } else if (form.password.length < 8) {
      errors.password = "Password must be at least 8 characters";
    }
    if (form.confirmPassword === "") {
      errors.confirmPassword = "Empty field";
    } else if (form.password !== form.confirmPassword) {
      errors.confirmPassword = "Passwords don't match";
    }

    return errors;
  };

  const {
    form,
    errors,
    response,
    send,
    handleChange,
    handleSubmit,
    resetForm,
  } = useForm(initialForm, validationForm);

  useEffect(() => {
    if (response) {
      dispatch(updateUserPassword(token, form)).then(function (res) {
        if (res && res.name === "AxiosError") {
        console.log(res)
          resetForm();
          setIsOpenErr(true);
        } else {
          resetForm();
          setIsOpen(true);
        }
      });
    }
  }, [response]);

  const loginRedirect = () => {
    history.push("/auth/login");
  };

  return (
    <>
      <form>
        <section style={{ width: "100%" }}>
          <CustomLabel
            color={"#525c60"}
            highlightColor={"#0aaa9d"}
            name={"Your new password"}
          />
          <InputPassword
            iconColor={"#525c6060"}
            inputId={"password"}
            inputName={"password"}
            inputValue={form.password}
            inputPlaceholder={"Min. 8 characters"}
            handleChange={handleChange}
          />
          {send && errors.password && <ErrorText err={errors.password} />}
        </section>
        <section style={{ width: "100%" }}>
          <CustomLabel
            color={"#525c60"}
            highlightColor={"#0aaa9d"}
            name={"Confirm new password"}
          />
          <InputPassword
            iconColor={"#525c6060"}
            inputId={"confirmPassword"}
            inputName={"confirmPassword"}
            inputValue={form.confirmPassword}
            inputPlaceholder={"Min. 8 characters"}
            handleChange={handleChange}
          />
          {send && errors.confirmPassword && (
            <ErrorText err={errors.confirmPassword} />
          )}
        </section>
        <Button variant="brand" onClick={handleSubmit}>
          Save
        </Button>
      </form>
      <Message
        isOpen={isOpen}
        closeModal={closeModal}
        setIsOpen={setIsOpen}
        width={isMobile ? 282 : 360}
        height={isMobile ? 175 : 185}
        title={"Success"}
        caption={"Your password was successfully updated."}
        buttonFunction={loginRedirect}
        buttonText={"Go login"}
      />
      <Message
        isOpen={isOpenErr}
        closeModal={closeModalErr}
        setIsOpen={setIsOpenErr}
        width={isMobile ? 200 : 400}
        height={isMobile ? 100 : 200}
        title={"Error"}
        caption={
          "The token to restore your password has expired. Go back to Login to request it again!"
        }
        buttonFunction={loginRedirect}
        buttonText={"Go login"}
      />
    </>
  );
}
