import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "./styles/chat.module.css";
// actions
import { getUserMessages } from "redux/actions/messages";
import { getUser } from "redux/actions/user";
import { getAllUsers } from "redux/actions/user";
// components
import { Box, Flex } from "@chakra-ui/react";
import Loader from "components/loader/Loader";
import Conversations from "./sections/Conversations/Conversations";
import Messages from "./sections/Messages/Messages";

export default function AdminChat() {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.currentUser);
  const clients = useSelector((state) => state.user.allUsers);

  // bring messages
  const [clientChat, setClientChat] = useState(null);
  const currentClient = useSelector((state) => state.user.userInfo);
  const data = useSelector((state) => state.message.currentMessages);

  useEffect(() => {
    dispatch(getAllUsers())
      .then(function () {
        setIsLoading(false);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [user]);

  useEffect(() => {
    if (clientChat) {
      setIsLoading(true);
      dispatch(getUserMessages(clientChat))
        .then(function () {
          dispatch(getUser(clientChat))
            .then(function () {
              setIsLoading(false);
            })
            .catch(function (err) {
              console.log(err);
            });
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }, [clientChat]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Flex pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex className={style.container}>
        <Box w="100%" color="black" borderRadius={20}>
          <Flex justifyContent={"space-between"} style={{ width: "100%" }}>
            <Conversations clients={clients} setClientChat={setClientChat} />
            <Messages
              client={currentClient}
              data={data}
            />
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
}
