import React from "react";
// components
import { Textarea } from "@chakra-ui/react";

export default function FormTextarea({
  textareaName,
  textareaValue,
  textareaPlaceholder,
  handleChange,
  isDisabled,
}) {
  return (
    <Textarea
      disabled={isDisabled ? isDisabled : false}
      fontSize="sm"
      ms={{ base: "0px", md: "0px" }}
      mb="24px"
      size="lg"
      resize="none"
      outline={"none"}
      boxShadow={"none"}
      borderRadius={5}
      name={textareaName}
      value={textareaValue}
      placeholder={textareaPlaceholder}
      onChange={handleChange}
      style={{ backgroundColor: "#fff" }}
    />
  );
}
