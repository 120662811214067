import axios from "axios";
import { server } from "constants/index";
import axiosInstance from "constants/axiosInstance";
import {
  ACCEPT_QUOTATION_MESSAGE,
  CLEAN_MESSAGE,
  CLEAR_USER_STATE,
  GET_ALL_USERS,
  GET_USER_BY_USER_ID,
  RESET_PASSWORD,
  UPDATE_USER,
  UPDATE_USER_PASSWORD,
  USER_LOGIN,
  USER_LOGIN_WITH_GOOGLE,
  USER_LOGOUT,
  USER_REGISTER,
  USER_VERIFY_WITH_GOOGLE,
} from "../actionTypes";

// provisory
export function acceptQuotationMessage() {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACCEPT_QUOTATION_MESSAGE,
        payload: `Hello! I want to accept a quotation. Please send me payment options.`,
      });
    } catch (error) {
      return error;
    }
  };
}
export function cleanMessage() {
  return async (dispatch) => {
    try {
      dispatch({
        type: CLEAN_MESSAGE,
      });
    } catch (error) {
      return error;
    }
  };
}

// actions that needs jwt authentication
export function getAllUsers() {
  return async (dispatch) => {
    try {
      const users = await axiosInstance.get("/users");
      dispatch({
        type: GET_ALL_USERS,
        payload: users.data,
      });
      return users.data;
    } catch (error) {
      return error;
    }
  };
}

export function getUser(userId) {
  return async (dispatch) => {
    try {
      const user = await axiosInstance.get(`/users/${userId}`);
      dispatch({
        type: GET_USER_BY_USER_ID,
        payload: user.data,
      });
      return user.data;
    } catch (error) {
      return error;
    }
  };
}

export function updateUser(id, data) {
  return async (dispatch) => {
    try {
      // Check updated fields
      const updateFields = {};
      if (data.name && data.name !== "") {
        updateFields.name = data.name;
      }
      if (data.phone && data.phone !== "") {
        updateFields.phone = data.phone;
      }
      if (data.password && data.password !== "") {
        updateFields.password = data.password;
      }
      // Executes the action with that fields
      const updatedInfo = await axiosInstance.patch(
        `/users/${id}`,
        updateFields
      );
      dispatch({
        type: UPDATE_USER,
        payload: updatedInfo.data,
      });
      return updatedInfo.data;
    } catch (error) {
      return error;
    }
  };
}

// actions that don't need authentication

// verify if user's jwt, is expired or not.
// if is, log out the user automatically. If not, the site allows user to navigate through the platform.
export function verifyUser(token) {
  return async (dispatch) => {
    try {
      const isValidToken = await axios.post(`${server}/users/tokenVerify`, {
        token: token,
      });
      return isValidToken;
    } catch (error) {
      dispatch({
        type: USER_LOGOUT,
      });
      return error;
    }
  };
}

export function register(data) {
  return async (dispatch) => {
    try {
      const newUser = await axios.post(`${server}/users/register`, data);
      dispatch({
        type: USER_REGISTER,
        payload: newUser.data,
        token: newUser.data.token,
      });
    } catch (error) {
      return error;
    }
  };
}

// verify the google token.
// this action is used to check if google token is a valid one.
// in case it is, the promise continue dispatching the register function (Google Register in RegisterForm section, inside Register's folder)
export function authGoogleAccount(data) {
  return async (dispatch) => {
    try {
      const verification = await axios.post(`${server}/users/googleVerify`, {
        token: data,
      });
      dispatch({
        type: USER_VERIFY_WITH_GOOGLE,
      });
      return verification;
    } catch (error) {
      return error;
    }
  };
}

export function logIn(data) {
  return async (dispatch) => {
    try {
      const user = await axios.post(`${server}/users/login`, data);
      dispatch({
        type: USER_LOGIN,
        payload: user.data,
        token: user.data.token,
      });
    } catch (error) {
      return error;
    }
  };
}

export function googleLogIn(data) {
  return async (dispatch) => {
    try {
      const user = await axios.post(`${server}/users/googleLogin`, {
        token: data,
      });
      dispatch({
        type: USER_LOGIN_WITH_GOOGLE,
        payload: user.data,
        token: user.data.token,
      });
    } catch (error) {
      return error;
    }
  };
}

export function logOut() {
  return (dispatch) => {
    dispatch({
      type: USER_LOGOUT,
    });
  };
}

export function updateUserPassword(idToken, data) {
  return async (dispatch) => {
    try {
      const updateFields = {};
      if (data.password && data.password !== "") {
        updateFields.password = data.password;
      }
      const update = await axios.patch(
        `${server}/users/resetPassword/${idToken}`,
        updateFields
      );
      dispatch({
        type: UPDATE_USER_PASSWORD,
        payload: update.data,
      });
      return update.data;
    } catch (error) {
      return error;
    }
  };
}

export function resetPassword(email) {
  return async (dispatch) => {
    try {
      const sendMail = await axios.post(`${server}/email/resetPassword`, email);
      dispatch({
        type: RESET_PASSWORD,
      });
      return sendMail;
    } catch (error) {
      return error;
    }
  };
}

export function clearRecentLoggedState() {
  return async (dispatch) => {
    try {
      dispatch({
        type: CLEAR_USER_STATE,
      });
    } catch (error) {
      return error;
    }
  };
}
