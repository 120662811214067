import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
// actions
import { getProject } from "redux/actions/project";
import { getResultsByProject } from "redux/actions/results";
import { getSamplesByProject } from "redux/actions/samples";
import { getUserQuotationsByProject } from "redux/actions/quotation";
// components
import { Flex } from "@chakra-ui/react";
import Loader from "components/loader/Loader";
import Header from "./sections/Header/Header";
import Information from "./sections/Information/Information";
import Quotations from "./sections/Quotations/Quotations";
import Orders from "./sections/Orders/Orders";
// utils
import { useResponsive } from "hooks/useResponsive";
import { getUserOrdersByProject } from "redux/actions/quotation";

export default function UserProjectAdminView() {
  const dispatch = useDispatch();
  let params = useParams();
  const isMobile = useResponsive();
  const [isLoading, setIsLoading] = useState(true);

  const project = useSelector((state) => state.project.currentProject);
  const projectQuotations = useSelector(
    (state) => state.quotation.projectQuotations
  );
  const projectOrders = useSelector((state) => state.quotation.projectOrders);
  const samples = useSelector((state) => state.sample.projectSamples);
  const results = useSelector((state) => state.result.projectResults);

  useEffect(() => {
    dispatch(getProject(params.id))
      .then(function (res) {
        dispatch(getSamplesByProject(params.id))
          .then(function () {
            dispatch(getResultsByProject(params.id))
              .then(function () {
                dispatch(getUserQuotationsByProject(params.id))
                  .then(function () {
                    dispatch(getUserOrdersByProject(params.id))
                      .then(function () {
                        setIsLoading(false);
                      })
                      .catch(function (err) {
                        console.log(err);
                      });
                  })
                  .catch(function (err) {
                    console.log(err);
                  });
              })
              .catch(function (err) {
                console.log(err);
              });
          })
          .catch(function (err) {
            console.log(err);
          });
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [params.id]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Flex flexDirection={"column"} gap={"20px"}>
      <Header />
      <Information data={project} />
      <Quotations data={projectQuotations} />
      <Orders data={projectOrders} />
    </Flex>
  );
}
