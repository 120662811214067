import React from "react";
// assets
import chatIcon from "../../../../../../assets/img/icons/chat.svg";
// components
import { Flex, Link } from "@chakra-ui/react";

export default function ChatButton() {
  return (
    <Flex style={{ cursor: "pointer" }}>
      <Link href="/#/client/messages">
        <img src={chatIcon} alt="Go to chat - Icon" />
      </Link>
    </Flex>
  );
}
