import React from "react";
// components
import Modal from "../Modal";
import { Flex } from "@chakra-ui/react";

export default function ModalForm({
  isOpen,
  closeModal,
  width,
  height,
  header,
  children,
}) {
  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      width={width}
      height={height}
    >
      <Flex flexDirection={"column"} width={"90%"}>
        <h2
          style={{
            color: "#0AAA9D",
            fontSize: 18,
            fontWeight: 600,
            textTransform: "uppercase",
          }}
        >
          {header}
        </h2>
        <hr style={{ marginTop: 10, marginBottom: 10 }} />
        {children}
      </Flex>
    </Modal>
  );
}
