import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes.js";
import { Box } from "@chakra-ui/react";
import { SidebarContext } from "contexts/SidebarContext";

export default function AuthRoutes() {
  const [toggleSidebar, setToggleSidebar] = useState(false);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };

  return (
    <Box>
      <SidebarContext.Provider value={{ toggleSidebar, setToggleSidebar }}>
        <Box mx="auto" minH="100vh">
          <Switch>
            {getRoutes(routes)}
            <Redirect from="/auth" to="/auth/sign-in/default" />
          </Switch>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
