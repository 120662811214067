import React from "react";
// assets
import uploadIcon from "../../../../../../assets/img/icons/upload.svg";
// components
import { Flex } from "@chakra-ui/react";
// utils
import { useModal } from "hooks/useModal";
import { useResponsive } from "hooks/useResponsive";
import CreateResult from "../../CreateResult/CreateResult";

export default function UploadButton({ id }) {
  const [isOpen, openModal, closeModal, setIsOpen] = useModal(false);
  const isMobile = useResponsive();

  return (
    <Flex style={{ cursor: "pointer" }}>
      <div onClick={() => setIsOpen(true)}>
        <img src={uploadIcon} alt="Upload Result - Icon" />
      </div>
      <CreateResult
        isOpen={isOpen}
        closeModal={closeModal}
        setIsOpen={setIsOpen}
        isMobile={isMobile}
        quotationId={id}
      />
    </Flex>
  );
}
