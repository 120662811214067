import React from "react";
import style from "./styles/boxError.module.css";
// components
import { Box, Button } from "@chakra-ui/react";

export default function BoxError({ message, button }) {
  return (
    <Box
      bg="white"
      w="100%"
      p={4}
      color="black"
      borderRadius={20}
      className={style.container}
    >
      <p>{message}</p>
      {button && <Button variant="brand">{button}</Button>}
    </Box>
  );
}
