import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import style from "../styles/messages.module.css";
// actions
import { createMessage } from "redux/actions/messages";
import { getUserMessages } from "redux/actions/messages";
// components
import { Button } from "@chakra-ui/react";
import FormTextarea from "components/inputs/FormTextarea/FormTextarea";
// utils
import { useForm } from "hooks/useForm";

export default function SendBox({ clientId }) {
  const dispatch = useDispatch();
  const initialForm = {
    contenido: "",
  };

  const validateForm = (form) => {
    let errors = {};

    if (form.contenido.trim() === "") {
      errors.contenido = "Please, write a message";
    }

    return errors;
  };

  const {
    form,
    errors,
    response,
    send,
    handleChange,
    handleSubmit,
    resetForm,
  } = useForm(initialForm, validateForm);

  useEffect(() => {
    if (response) {
      dispatch(
        createMessage({
          contenido: form.contenido,
          estado: "new",
          emisor: 1,
          receptor: clientId,
        })
      )
        .then(function () {
          resetForm();
          dispatch(getUserMessages(clientId));
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }, [response]);

  return (
    <div className={style.sendBox}>
      <hr className={style.line} />
      <FormTextarea
        textareaName={"contenido"}
        textareaValue={form.contenido}
        textareaPlaceholder={"Type something..."}
        handleChange={handleChange}
      />
      <Button variant="brand" type="submit" onClick={handleSubmit}>
        Send
      </Button>
    </div>
  );
}
