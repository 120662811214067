import React from "react";
import style from "../../styles/newService.module.css";
// components
import { Box, Flex } from "@chakra-ui/react";
import BoxError from "components/boxError/BoxError";
import QuotationTable from "../../components/QuotationTable/QuotationTable";
import CreateQuotation from "../CreateQuotation/CreateQuotation";
// variables
import columnsDataDevelopment from "../../variables/columnsDataQuotation";

export default function Quotations({
  data,
  isMobile,
}) {
  return (
    <Flex className={style.componentContainer}>
      {data && data[0] !== undefined ? (
        <Box bg="white" w="100%" p={4} color="black" borderRadius={20}>
          <QuotationTable
            columnsData={columnsDataDevelopment}
            tableData={data}
            isMobile={isMobile}
          />
        </Box>
      ) : (
        <BoxError message={"There aren't any quotations"} />
      )}
    </Flex>
  );
}
