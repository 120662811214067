import React, { useState } from "react";
// components
import { Icon, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
// icons --> can be replaced by images
import { RiEyeCloseLine } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";

export default function InputPassword({
  iconColor,
  inputId,
  inputName,
  inputValue,
  inputPlaceholder,
  handleChange,
}) {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <InputGroup size="md">
      <Input
        type={show ? "text" : "password"}
        isRequired={true}
        fontSize="sm"
        mb="24px"
        size="lg"
        borderRadius={5}
        variant="auth"
        id={inputId}
        name={inputName}
        value={inputValue}
        placeholder={inputPlaceholder}
        onChange={handleChange}
        style={{ backgroundColor: "#fff" }}
      />
      <InputRightElement display="flex" alignItems="center" mt="4px">
        <Icon
          color={iconColor}
          _hover={{ cursor: "pointer" }}
          as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
          onClick={handleClick}
        />
      </InputRightElement>
    </InputGroup>
  );
}
