// Client views
import NewService from "views/client/NewService/NewService.jsx";
import UserProjects from "views/client/Projects/Projects";
import UserProjectView from "views/client/Project/Project";
import Profile from "views/client/Profile/Profile";
import Chat from "views/client/Chat/Chat";

//Admin views
import Services from "views/admin/Services/Services";
import Projects from "views/admin/Projects/Projects";
import UserProjectAdminView from "views/admin/Project/Project";
import Users from "views/admin/Users/Users";
import User from "views/admin/User/User";
import AdminChat from "views/admin/Chat/Chat";

// Authentication views
import Login from "views/auth/Login/Login";
import Register from "views/auth/Register/Register";
import ChangePassword from "views/auth/ChangePassword/ChangePassword";

const routes = [
  //Client routes
  {
    name: "My Services",
    layout: "/client",
    path: "/services",
    component: NewService,
  },
  {
    name: "My projects",
    layout: "/client",
    path: "/projects",
    component: UserProjects,
  },
  {
    name: "Project",
    layout: "/client",
    path: "/project/:id",
    component: UserProjectView,
  },
  {
    name: "Profile",
    layout: "/client",
    path: "/profile",
    component: Profile,
  },
  {
    name: "Messages",
    layout: "/client",
    path: "/messages",
    component: Chat,
  },

  //Admin routes
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/dashboard",
    component: Services,
  },
  {
    name: "Projects",
    layout: "/admin",
    path: "/projects",
    component: Projects,
  },
  {
    name: "Project",
    layout: "/admin",
    path: "/project/:id",
    component: UserProjectAdminView,
  },
  {
    name: "Users",
    layout: "/admin",
    path: "/users",
    component: Users,
  },
  {
    name: "User",
    layout: "/admin",
    path: "/user/:id",
    component: User,
  },
  {
    name: "Messages",
    layout: "/admin",
    path: "/messages",
    component: AdminChat,
  },

  //Auth routes
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    component: Login,
  },
  {
    name: "Register",
    layout: "/auth",
    path: "/register",
    component: Register,
  },
  {
    name: "ResetPassword",
    layout: "/auth",
    path: "/resetPassword/:id",
    component: ChangePassword,
  },
];

export default routes;
