import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import style from "../../styles/conversations.module.css";
// actions
import { getNewAdminMessages } from "redux/actions/messages";
import { readClientMessages } from "redux/actions/messages";
// assets
import dotIcon from "../../../../../assets/img/icons/dot.svg";
// components
import { Flex } from "@chakra-ui/react";

const UserItem = ({ client, setClientChat }) => {
  const dispatch = useDispatch();
  const [isNewMsg, setIsNewMsg] = useState(0);

  useEffect(() => {
    dispatch(getNewAdminMessages(client.id))
      .then(function (res) {
        setIsNewMsg(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [client]);

  const onClickFunction = (clientId) => {
    setClientChat(clientId);
    dispatch(readClientMessages(clientId)).then(function () {
      dispatch(getNewAdminMessages(clientId))
        .then(function (res) {
          setIsNewMsg(res);
        })
        .catch(function (err) {
          console.log(err);
        });
    });
  };

  return (
    <Flex
      className={`${style.conversation} ${isNewMsg !== 0 && style.newMessage}`}
      onClick={() => onClickFunction(client.id)}
    >
      <div className={style.avatar}>
        <p className={style.avatarLetter}>{client.name[0]}</p>
      </div>
      <p>{client.name}</p>

      {isNewMsg !== 0 && (
        <img src={dotIcon} alt="New Message" className={style.newMessageIcon} />
      )}
    </Flex>
  );
};

export default function Conversations({ clients, setClientChat }) {
  return (
    <Flex className={style.container}>
      <div className={style.scroll}>
        {clients &&
          clients.map((client) => (
            <UserItem
              key={client.id}
              client={client}
              setClientChat={setClientChat}
            />
          ))}
      </div>
    </Flex>
  );
}
