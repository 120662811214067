import React from "react";
// components
import { Flex } from "@chakra-ui/react";
import DetailButton from "../buttons/DetailButton";
import EditButton from "../buttons/EditButton";

export default function ProjectActions({ id, isMobile }) {
  return (
    <Flex gap={10} alignItems={"center"}>
      <DetailButton id={id} />
      <EditButton id={id} isMobile={isMobile} />
    </Flex>
  );
}
