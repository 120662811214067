import React from "react";
// components
import { Input } from "@chakra-ui/react";

export default function FormInput({
  inputId,
  inputName,
  inputValue,
  inputPlaceholder,
  handleChange,
  isRequired,
  isDisabled,
  disabledStyle,
  styles,
}) {
  return (
    <Input
      type="text"
      isRequired={isRequired ? isRequired : false}
      disabled={isDisabled ? isDisabled : false}
      cursor={isDisabled && "not-allowed"}
      fontSize="sm"
      ms={{ base: "0px", md: "0px" }}
      mb="24px"
      size="lg"
      borderRadius={5}
      variant="auth"
      id={inputId}
      name={inputName}
      value={inputValue}
      placeholder={inputPlaceholder}
      onChange={handleChange}
      style={{ backgroundColor: isDisabled || disabledStyle ? "#FAFAFA" : "#fff", ...styles }}
    />
  );
}
