/* eslint-disable */
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  Box,
  Flex,
  HStack,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import axios from "axios";
import { server } from "constants/index";

export function SidebarLinks(props) {
  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");

  const { routes } = props;

  //Redux implementation
  const user = useSelector((state) => state.user.currentUser);
  const token = useSelector((state) => state.user.currentToken);
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const filterRoutes = () => {
    let rutas;
    if (user && user.rol === "Administrador") {
      rutas = routes.filter((route) => route.layout === "/admin");
    } else if (user && user.rol === "Cliente") {
      rutas = routes.filter((route) => route.layout === "/client");
    } else {
      rutas = routes.filter((route) => route.layout === "/auth");
    }
    return rutas;
  };

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    if (
      routeName.startsWith("/ticket") &&
      location.pathname.includes("ticket")
    ) {
      return true;
    } else if (
      routeName.startsWith("/message") &&
      location.pathname.includes("chat")
    ) {
      return true;
    } else {
      return location.pathname.includes(routeName);
    }
    //return location.pathname.includes(routeName);
  };

  const [messages, setMessages] = useState([]);

  const fetchMessages = async () => {
    try {
      const response = await axios.get(server + "/mensajes/user/" + user.id);
      setMessages(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (route.category) {
        return (
          <>
            <Text
              fontSize={"md"}
              color={activeColor}
              fontWeight="bold"
              mx="auto"
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              pt="18px"
              pb="12px"
              key={index}
            >
              {route.name}
            </Text>
            {createLinks(route.items)}
          </>
        );
      } else if (
        route.layout === "/admin" ||
        route.layout === "/auth" ||
        route.layout === "/client" ||
        route.layout === "/rtl"
      ) {
        return (
          <NavLink key={index} to={route.layout + route.path}>
            {route.icon ? (
              <Box>
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                  py="5px"
                  ps="10px"
                >
                  <Flex w="100%" alignItems="center" justifyContent="center">
                    <Box
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeIcon
                          : textColor
                      }
                      me="18px"
                    >
                      {route.icon}
                    </Box>
                    <Text
                      me="auto"
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : textColor
                      }
                      fontWeight={
                        activeRoute(route.path.toLowerCase())
                          ? "bold"
                          : "normal"
                      }
                    >
                      {route.name}
                    </Text>
                  </Flex>
                  <Box
                    h="36px"
                    w="4px"
                    bg={
                      activeRoute(route.path.toLowerCase())
                        ? brandColor
                        : "transparent"
                    }
                    borderRadius="5px"
                  />
                </HStack>
              </Box>
            ) : (
              <Box>
                {route.name === "Quotation" ||
                route.name === "Project" ||
                route.name === "User" ||
                route.name === "Chat" ? null : (
                  <HStack
                    spacing={
                      activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                    }
                    py="5px"
                    ps="10px"
                    marginTop={1}
                  >
                    <Text
                      me="auto"
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : inactiveColor
                      }
                      fontWeight={
                        activeRoute(route.path.toLowerCase())
                          ? "bold"
                          : "normal"
                      }
                    >
                      {route.name}
                    </Text>
                    <div
                      style={{
                        position: "absolute",
                        left: 110,
                      }}
                    >
                      {/*route.name === "Messages" && newMessages.length > 0 ? (
                        <Icon as={WarningIcon} color="brand.400" />
                      ) : null*/}
                    </div>
                    {activeRoute(route.path.toLowerCase()) ? (
                      <Box h="25px" w="4px" bg="brand.400" borderRadius="5px" />
                    ) : null}
                  </HStack>
                )}
              </Box>
            )}
          </NavLink>
        );
      }
    });
  };
  return createLinks(filterRoutes());
}

export default SidebarLinks;
