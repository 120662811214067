import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import style from "./styles/project.module.css";
// actions
import { getProject } from "redux/actions/project";
import { getUserQuotationsByProject } from "redux/actions/quotation";
import { getUserOrdersByProject } from "redux/actions/quotation";
// components
import Loader from "components/loader/Loader";
import Header from "./sections/Header/Header";
import Information from "./sections/Information/Information";
import Quotations from "./sections/Quotations/Quotations";
import Orders from "./sections/Orders/Orders";
// utils
import { useResponsive } from "hooks/useResponsive";

export default function UserProjectView() {
  const dispatch = useDispatch();
  const isMobile = useResponsive();
  const [isLoading, setIsLoading] = useState(true);
  let params = useParams();

  const project = useSelector((state) => state.project.currentProject);
  const projectQuotations = useSelector(
    (state) => state.quotation.projectQuotations
  );
  const projectOrders = useSelector((state) => state.quotation.projectOrders);

  useEffect(() => {
    dispatch(getProject(params.id))
      .then(function (res) {
        dispatch(getUserQuotationsByProject(params.id))
          .then(function (res) {
            dispatch(getUserOrdersByProject(params.id))
              .then(function () {
                setIsLoading(false);
              })
              .catch(function (err) {
                console.log(err);
              });
          })
          .catch(function (err) {
            console.log(err);
          });
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [params.id]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={style.container}>
      <Header />
      <Information data={project} isMobile={isMobile} />
      <Quotations data={projectQuotations} />
      <Orders data={projectOrders} />
    </div>
  );
}
