import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "./styles/profile.module.css";
// actions
import { getUser } from "redux/actions/user";
// components
import { Flex } from "@chakra-ui/react";
import CompaniesInformation from "./sections/CompaniesInformation/CompaniesInformation";
import PersonalInformation from "./sections/PersonalInformation/PersonalInformation";
import Loader from "components/loader/Loader";
// utils
import { useResponsive } from "hooks/useResponsive";

export default function Profile() {
  const dispatch = useDispatch();
  const isMobile = useResponsive();
  const user = useSelector((state) => state.user.currentUser);
  const userInfo = useSelector((state) => state.user.userInfo);
  const companies = useSelector((state) => state.company.allUserCompanies);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getUser(user.id))
      .then(function () {
        setIsLoading(false);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [user, companies]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Flex
      pt={{ base: "130px", md: "80px", xl: "80px" }}
      className={style.container}
    >
      <CompaniesInformation
        data={userInfo.companies}
        user={userInfo}
        isMobile={isMobile}
      />
      <PersonalInformation data={userInfo} />
    </Flex>
  );
}
