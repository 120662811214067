import React from "react";
// components
import { Flex } from "@chakra-ui/react";
import DetailButton from "../buttons/DetailButton";

export default function UserActions({ id }) {
  return (
    <Flex gap={10} alignItems={"center"}>
      <DetailButton userId={id} />
    </Flex>
  );
}
