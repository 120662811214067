import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// actions
import { updateProject } from "redux/actions/project";
import { getProjects } from "redux/actions/project";
// components
import Modal from "components/modal/Modal";
import { Button, Flex } from "@chakra-ui/react";
import CustomLabel from "components/customLabel/CustomLabel";
import FormInput from "components/inputs/FormInput/FormInput";
import FormTextarea from "components/inputs/FormTextarea/FormTextarea";
import ErrorText from "components/errorText/ErrorText";
import Message from "../Message/Message";
// utils
import { useModal } from "hooks/useModal";
import { useForm } from "hooks/useForm";

export default function EditProject({
  id,
  data,
  isOpen,
  closeModal,
  setIsOpen,
  isMobile,
}) {
  const dispatch = useDispatch();
  const [isOpenSuccess, openModalSuccess, closeModalSuccess, setIsOpenSuccess] =
    useModal(false);

  const initialForm = {
    title: "",
    details: "",
  };

  const validateForm = (form) => {
    let errors = {};

    if (
      form.title.trim() === data.title.trim() &&
      form.details.trim() === data.details.trim()
    ) {
      errors.details = "Any change was detected";
    }
    if (form.title.trim() === data.title.trim()) {
      errors.details = "Any change was detected";
    }
    if (form.details.trim() === data.details.trim()) {
      errors.details = "Any change was detected";
    }

    if (form.title.trim() === "" && form.details.trim() === "") {
      errors.details = "Empty fields";
    }

    if (form.details.trim().length > 255) {
      errors.details = "Maximum 255 characters long";
    }

    return errors;
  };

  const {
    form,
    errors,
    response,
    send,
    handleChange,
    handleSubmit,
    resetForm,
  } = useForm(initialForm, validateForm);

  useEffect(() => {
    if (response) {
      dispatch(updateProject(id, form))
        .then(function () {
          dispatch(getProjects(data.user.id)).then(function () {
            resetForm();
            setIsOpen(false);
            setIsOpenSuccess(true);
          });
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }, [response]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        width={isMobile ? 320 : 513}
        height={355}
      >
        <Flex flexDirection={"column"} width={"90%"}>
          <Flex
            className="title"
            style={{
              textTransform: "uppercase",
            }}
          >
            <h2 style={{ color: "#0AAA9D", fontSize: 18, fontWeight: 600 }}>
              Edit project
            </h2>
          </Flex>
          <hr style={{ marginTop: 10, marginBottom: 10 }} />
          <form>
            <section>
              <CustomLabel color={"#525c60"} name={"Project title"} />
              <FormInput
                inputId={"projectTitle"}
                inputName={"title"}
                inputValue={form.title}
                inputPlaceholder={data.title}
                handleChange={handleChange}
              />
              {send && errors.title && <ErrorText err={errors.title} />}
            </section>
            <section>
              <CustomLabel color={"#525c60"} name={"Project aim & details"} />
              <FormTextarea
                textareaName={"details"}
                textareaValue={form.details}
                textareaPlaceholder={data.details}
                handleChange={handleChange}
              />
              {send && errors.details && <ErrorText err={errors.details} />}
            </section>
            <Flex justifyContent={"flex-end"} gap={5} mb={5}>
              <Button onClick={closeModal}>Cancel</Button>
              <Button variant="brand" type="submit" onClick={handleSubmit}>
                Save
              </Button>
            </Flex>
          </form>
        </Flex>
      </Modal>
      <Message
        isOpen={isOpenSuccess}
        closeModal={closeModalSuccess}
        setIsOpen={setIsOpenSuccess}
        width={isMobile ? null : 200}
        height={isMobile ? null : 300}
        title={"Success"}
        caption={"Project updated correctly."}
        buttonFunction={closeModalSuccess}
        buttonText={"Accept"}
      />
    </>
  );
}
