import React from "react";
// components
import { Button } from "@chakra-ui/react";

export default function GreenButton({ text, onClick, isDisabled }) {
  return (
    <Button
      variant="brand"
      onClick={onClick}
      style={{
        pointerEvents: isDisabled && "none",
        filter: isDisabled && "grayscale(100%)",
      }}
    >
      {text}
    </Button>
  );
}
