import {
  CREATE_NEW_PROJECT,
  GET_ALL_PROJECTS,
  GET_PROJECT,
  GET_PROJECTS_BY_USER_ID,
  UPDATE_PROJECT,
} from "redux/actions/actionTypes";

const initialState = {
  allProjects: null,
  allUserProjects: null,
  currentProject: null,
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NEW_PROJECT:
      return {
        ...state,
      };
    case GET_ALL_PROJECTS:
      return {
        ...state,
        allProjects: action.payload,
      };
    case GET_PROJECTS_BY_USER_ID:
      return {
        ...state,
        allUserProjects: action.payload,
      };
    case GET_PROJECT:
      return {
        ...state,
        currentProject: action.payload,
      };
    case UPDATE_PROJECT:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default projectReducer;
