import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// components
import { Button, Flex } from "@chakra-ui/react";
import Modal from "components/modal/Modal";
import CustomLabel from "components/customLabel/CustomLabel";
import FormInput from "components/inputs/FormInput/FormInput";
// utils
import { useForm } from "hooks/useForm";
import { resetPassword } from "redux/actions/user";

export default function ForgotPasswordModal({
  isOpen,
  closeModal,
  isMobile,
  onSuccess,
  onError,
}) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const initialForm = {
    email: "",
  };

  const validationForm = (form) => {
    let errors = {};
    let regexEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!form.email.trim()) {
      errors.email = "Empty field";
    } else if (!regexEmail.test(form.email.trim())) {
      errors.email = "The email format isn't correct";
    }

    return errors;
  };

  const {
    form,
    errors,
    response,
    send,
    handleChange,
    handleSubmit,
    resetForm,
  } = useForm(initialForm, validationForm);

  useEffect(() => {
    if (response) {
      setIsLoading(true);
      dispatch(resetPassword(form))
        .then(function (res) {
          setIsLoading(false);
          resetForm();
          if (res && res.name === "AxiosError") {
            onError();
          } else {
            onSuccess();
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }, [response]);

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      width={isMobile ? 320 : 450}
      height={isMobile ? 200 : 220}
    >
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          justifyContent: "center",
        }}
      >
        <section>
          <CustomLabel
            color={"#525c60"}
            name={"Leave your email to reset your password."}
          />
          <FormInput
            inputId={"email"}
            inputName={"email"}
            inputValue={form.email}
            inputPlaceholder={"For example: mail@example.com"}
            handleChange={handleChange}
          />
        </section>
        <Flex style={{ width: "100%" }} justifyContent={"space-between"}>
          <Button onClick={closeModal}>Close</Button>
          <Button variant="brand" type="submit" onClick={handleSubmit}>
            Send
          </Button>
        </Flex>
      </form>
    </Modal>
  );
}
