import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getProjects } from "redux/actions/project";

const useUserProjects = (userId) => {
  const dispatch = useDispatch();
  const [projects, setProjects] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProjects = () => {
      try {
        dispatch(getProjects(userId))
          .then((data) => setProjects(data))
          .catch((err) => console.log(err))
          .finally(() => setIsLoading(false));
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    };

    fetchProjects();
  }, [userId]);

  return { projects, isLoading };
};

export default useUserProjects;
