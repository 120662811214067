export const columnsDataCompanies = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Position",
    accessor: "position",
  },
  {
    Header: "Actions",
    accessor: "id",
  },
];

export default columnsDataCompanies;
