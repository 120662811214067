import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "./styles/services.module.css";
// actions
import { getAllQuotations } from "redux/actions/quotation";
import { getAllOrders } from "redux/actions/quotation";
// components
import { Flex } from "@chakra-ui/react";
import Quotations from "./sections/Quotations/Quotations";
import Orders from "./sections/Orders/Orders";

export default function Services() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const token = useSelector((state) => state.user.currentToken);
  const quotations = useSelector((state) => state.quotation.allQuotations);
  const orders = useSelector((state) => state.quotation.allOrders);

  useEffect(() => {
    if (token) {
      dispatch(getAllQuotations())
        .then(function () {
          dispatch(getAllOrders()).catch(function (err) {
            console.log(err);
          });
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }, [user, token]);

  return (
    <Flex
      pt={{ base: "130px", md: "80px", xl: "80px" }}
      className={style.container}
    >
      <Quotations data={quotations} />
      <Orders data={orders} />
    </Flex>
  );
}
