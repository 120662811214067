import React from "react";
// components
import { Button } from "@chakra-ui/react";
import CreateCompany from "./CreateCompany";
// utils
import { useModal } from "hooks/useModal";

export default function CreateCompanyButton({ user, isMobile }) {
  const [isOpen, openModal, closeModal, setIsOpen] = useModal(false);

  return (
    <>
      <Button variant="brand" type="submit" onClick={() => setIsOpen(true)}>
        Add company
      </Button>
      <CreateCompany
        isOpen={isOpen}
        closeModal={closeModal}
        setIsOpen={setIsOpen}
        isMobile={isMobile}
        user={user}
      />
    </>
  );
}
