import React, { useEffect, useState } from "react";
import style from "../../../../../styles/form.module.css";
// components
import { Flex } from "@chakra-ui/react";
import {
  FormInputItem,
  FormInputAndSelectItem,
} from "components/formItems/FormItems";
import GreenButton from "components/buttons/GreenButton";

export default function PersonalSection({
  form,
  handleChange,
  changeView,
  closeModal,
  errors,
  send,
  user,
  userCompanies,
  selectedCompany,
  setSelectedCompany,
}) {
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    if (errors.companyName || errors.companyPosition) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [errors]);

  return (
    <Flex className={style.formContainer}>
      <form className={style.form}>
        <FormInputAndSelectItem
          label={"Company / Institute "}
          isRequired={true}
          name={"companyName"}
          value={form.companyName}
          placeholder={""}
          handleChange={handleChange}
          data={userCompanies ? userCompanies : null}
          matchingAttribute={"name"}
          setSelectedData={setSelectedCompany}
          err={null}
        />
        <FormInputItem
          isDisabled={selectedCompany ? true : false}
          label={"Position"}
          isRequired={selectedCompany ? false : true}
          name={"companyPosition"}
          value={form.companyPosition}
          placeholder={""}
          handleChange={handleChange}
          err={
            null /*send && errors.companyPosition ? errors.companyPosition : null*/
          }
        />
        <FormInputItem
          isDisabled={true}
          label={"Name"}
          isRequired={true}
          name={"userName"}
          value={user && user.name}
          placeholder={user && user.name}
          err={null /*send && errors.userName ? errors.userName : null*/}
        />
        <FormInputItem
          isDisabled={true}
          label={"Email"}
          isRequired={true}
          name={"userEmail"}
          value={user && user.email}
          placeholder={user && user.email}
          err={null /*send && errors.userEmail ? errors.userEmail : null*/}
        />
        <FormInputItem
          isDisabled={user.phone ? true : false}
          label={"Phone number"}
          isRequired={false}
          name={"userPhone"}
          value={user && user.phone ? user.phone : form.userPhone}
          placeholder={user && user.phone ? user.phone : ""}
          handleChange={handleChange}
        />
        <Flex className={style.buttonsContainer}>
          <div onClick={closeModal}>
            <h6>Skip</h6>
          </div>
          <GreenButton
            text={"Next"}
            onClick={() => changeView(2)}
            isDisabled={isDisabled}
          />
        </Flex>
      </form>
    </Flex>
  );
}
