import React from "react";
import style from "../styles/quotationDetail.module.css";
// components
import { Flex } from "@chakra-ui/react";
import CustomLabel from "components/customLabel/CustomLabel";
import FormInput from "components/inputs/FormInput/FormInput";
import FormTextarea from "components/inputs/FormTextarea/FormTextarea";
import FormCustomCheckbox from "components/inputs/FormCustomCheckbox/FormCustomCheckbox";

export default function ProjectDetails({ data, solution }) {
  const knowSampleServices = [
    { name: "Identity" },
    { name: "Compare with other strain" },
    { name: "Characterization" },
    { name: "Security (Pathogenicity, Antibiotics Resistance)" },
  ];

  const includeServices = [{ name: "DNA Extraction" }, { name: "Sequencing" }];

  const clientServices = solution.service.map((service) => service.name);

  return (
    <Flex flexDirection={"column"} width={"100%"} style={{ marginBottom: 10 }}>
      <h3 className={style.title}>Project Details</h3>
      <Flex>
        <section className={style.sectionContainer}>
          <CustomLabel name={"Project Name"} color={"#525c60"} />
          <FormInput
            isDisabled={true}
            inputId={data.project.title}
            inputName={"projectName"}
            inputValue={data.project.title}
            inputPlaceholder={data.project.title}
          />
        </section>
      </Flex>
      <Flex>
        <section className={style.sectionContainer}>
          <CustomLabel name={"Description (Optional)"} color={"#525c60"} />
          <FormTextarea
            isDisabled={true}
            textareaName={"projectDetails"}
            textareaValue={data.project.details}
            textareaPlaceholder={data.project.details}
          />
        </section>
      </Flex>
      {solution.solution.id == 1 ? (
        <section>
          <CustomLabel name={"Include"} color={"#525c60"} />
          <Flex gap={10}>
            {includeServices.map((service, index) => (
              <FormCustomCheckbox
                key={index}
                inputName={"services"}
                inputValue={service.name}
                content={service.name}
                disabled={true}
                defaultChecked={clientServices.includes(service.name)}
              />
            ))}
          </Flex>
        </section>
      ) : solution.solution.id == 2 ? (
        <>
          <section>
            <CustomLabel
              name={"What do you want to know about your sample?"}
              color={"#525c60"}
            />
            <Flex style={{ flexWrap: "wrap" }}>
              {knowSampleServices.map((service, index) => (
                <div style={{marginRight: 20}}>
                  <FormCustomCheckbox
                    key={index}
                    inputName={"services"}
                    inputValue={service.name}
                    content={service.name}
                    disabled={true}
                    defaultChecked={clientServices.includes(service.name)}
                  />
                </div>
              ))}
            </Flex>
          </section>
          <section>
            <CustomLabel name={"Include"} color={"#525c60"} />
            <Flex gap={10}>
              {includeServices.map((service, index) => (
                <FormCustomCheckbox
                  key={index}
                  inputName={"services"}
                  inputValue={service.name}
                  content={service.name}
                  disabled={true}
                  defaultChecked={clientServices.includes(service.name)}
                />
              ))}
            </Flex>
          </section>
        </>
      ) : null}
    </Flex>
  );
}
