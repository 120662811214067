import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import style from "../../../styles/profile.module.css";
// components
import { Box, Button, Flex } from "@chakra-ui/react";
import CustomLabel from "components/customLabel/CustomLabel";
import FormInput from "components/inputs/FormInput/FormInput";
// utils
import { useForm } from "hooks/useForm";
import { updateUser } from "redux/actions/user";
import BoxError from "components/boxError/BoxError";
import ErrorText from "components/errorText/ErrorText";

export default function EditableInfo({ data, cancelFunction }) {
  const dispatch = useDispatch();
  const initialForm = {
    name: "",
    phone: "",
  };

  const validateForm = (form) => {
    let errors = {};

    if (form.name.trim() === "" && form.phone.trim() === "") {
      errors.form = "Any change was detected";
    }

    return errors;
  };

  const {
    form,
    errors,
    response,
    send,
    handleChange,
    handleSubmit,
    resetForm,
  } = useForm(initialForm, validateForm);

  useEffect(() => {
    if (response) {
      dispatch(updateUser(data.id, form))
        .then(function () {
          cancelFunction();
          resetForm();
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }, [response]);

  return (
    <Box bg="transparent" w="100%" p={4} color="black" borderRadius={20}>
      <form>
        <section className={style.section}>
          <CustomLabel
            color={"#525c60"}
            highlightColor={"#0aaa9d"}
            name={`Name`}
          />
          <div className={style.inputContainer}>
            <FormInput
              inputId={"name"}
              inputName={"name"}
              inputValue={form.name}
              inputPlaceholder={""}
              handleChange={handleChange}
            />
          </div>
        </section>
        <Flex justifyContent={"space-between"} gap={2.5}>
          <section className={style.section}>
            <CustomLabel
              color={"#525c60"}
              highlightColor={"#0aaa9d"}
              name={`Email`}
            />
            <div className={style.inputContainer}>
              <FormInput
                inputId={"email"}
                inputName={"email"}
                inputValue={data.email}
                inputPlaceholder={data.email}
                isDisabled={true}
                disabledStyle={true}
              />
            </div>
          </section>
          <section className={style.section}>
            <CustomLabel
              color={"#525c60"}
              highlightColor={"#0aaa9d"}
              name={`Phone number`}
            />
            <div className={style.inputContainer}>
              <FormInput
                inputId={"phone"}
                inputName={"phone"}
                inputValue={form.phone}
                inputPlaceholder={""}
                handleChange={handleChange}
              />
            </div>
          </section>
        </Flex>
        {send && errors && (
          <div style={{marginTop: 15}}>
            <ErrorText err={errors.form} />
          </div>
        )}
        <Flex justifyContent={"flex-end"} gap={5}>
          <Button onClick={cancelFunction}>Cancel</Button>
          <Button variant="brand" onClick={handleSubmit}>
            Save
          </Button>
        </Flex>
      </form>
    </Box>
  );
}
