import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import ScrollToTop from "components/scrollToTop/ScrollToTop";
// Routes definition
import AuthRoutes from "routes/auth";
import AdminLayout from "layouts/admin";
import ClientLayout from "layouts/client";
// Token validation
import { verifyUser } from "redux/actions/user";

export default function UserRoutes() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);

  // Token validation
  useEffect(() => {
    if (user) {
      dispatch(verifyUser(user.token)).then(function (res) {
        if (res && res.data) {
          // Setting the jwt at Local Storage in case of success
          localStorage.setItem("jwt", user.token);
        } else {
          // Delete the jwt value in Local Storage
          localStorage.removeItem("jwt");
        }
      });
    }
  }, [user]);

  return (
    <HashRouter>
      <ScrollToTop />
      {user && user.rol === "Cliente" ? (
        <Switch>
          <Route path={`/client`} component={ClientLayout} />
          <Redirect from="/" to="/client" />
        </Switch>
      ) : user && user.rol === "Administrador" ? (
        <Switch>
          <Route path={`/admin`} component={AdminLayout} />
          <Redirect from="/" to="/admin" />
        </Switch>
      ) : (
        <Switch>
          <Route path={`/auth`} component={AuthRoutes} />
          <Redirect from="/" to="/auth" />
        </Switch>
      )}
    </HashRouter>
  );
}
