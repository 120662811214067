import React from "react";

// Chakra imports
import { Flex, useColorModeValue } from "@chakra-ui/react";

// Custom components
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align="center" direction="column">
      <img
        style={{ paddingBottom: 20 }}
        src={
          "https://res.cloudinary.com/dsxfufacm/image/upload/v1692723758/Taxon/logo-taxon-descriptivo_tz1suj.png"
        }
      />
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
