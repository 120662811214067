import React from "react";
import style from "../../styles/profile.module.css";
// components
import { Box, Button, Flex } from "@chakra-ui/react";
import Card from "components/card/Card";
import BoxError from "components/boxError/BoxError";
import CompaniesTable from "../../components/CompaniesTable/CompaniesTable";
import CreateCompany from "../../components/CreateCompany/CreateCompanyButton";
// variables
import columnsDataCompanies from "../../variables/columnsDataCompanies";

export default function CompanyInformation({ data, user, isMobile }) {
  return (
    <Card>
      <Flex flexDirection={"column"}>
        <Box bg="white" w="100%" p={4} color="black" borderRadius={20}>
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <div className={style.headerInfo}>
              <h2 className={style.sectionTitle}>Company / Institute</h2>
              <p>
                We would like to know which company you work for or if you
                belong to a research institute, university, or academic unit
              </p>
            </div>
            <CreateCompany user={user} isMobile={isMobile} />
          </Flex>
        </Box>
        {data && data[0] !== undefined ? (
          <CompaniesTable
            columnsData={columnsDataCompanies}
            tableData={data}
            isMobile={isMobile}
          />
        ) : (
          <BoxError message={"There aren't companies yet."} />
        )}
      </Flex>
    </Card>
  );
}
