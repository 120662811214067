import React from "react";
import style from "./styles/loader.module.css";
// components
import { Spinner, useColorModeValue } from "@chakra-ui/react";

export default function Loader() {
  const color = useColorModeValue("brand.500", "brand.400");

  return (
    <div className={style.container}>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color={color}
        size="xl"
      />
    </div>
  );
}
