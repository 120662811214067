import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// actions
import { getCompany } from "redux/actions/company";
// assets
import eyeIcon from "../../../../../../assets/img/icons/eye.svg";
// components
import { Flex } from "@chakra-ui/react";
import DetailCompany from "../../DetailCompany/DetailCompany";
// utils
import { useModal } from "hooks/useModal";

export default function DetailButton({ id, isMobile }) {
  const [isOpen, openModal, closeModal, setIsOpen] = useModal(false);

  return (
    <>
      <Flex style={{ cursor: "pointer" }} onClick={() => setIsOpen(true)}>
        <img src={eyeIcon} alt="See detail - Icon" />
      </Flex>
      <DetailCompany
        isOpen={isOpen}
        closeModal={closeModal}
        isMobile={isMobile}
        id={id}
      />
    </>
  );
}
