import {
  GET_MESSAGES_BY_USER_ID,
  GET_ADMIN_UNREAD_MESSAGES,
  GET_USER_UNREAD_MESSAGES,
  READ_USER_MESSAGES,
  READ_ADMIN_MESSAGES,
  CREATE_MESSAGE,
} from "redux/actions/actionTypes";

const initialState = {
  currentMessages: null,
};

const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_MESSAGE:
      return {
        ...state,
      };
    case GET_MESSAGES_BY_USER_ID:
      return {
        ...state,
        currentMessages: action.payload,
      };
    case GET_USER_UNREAD_MESSAGES:
      return {
        ...state,
      };
    case GET_ADMIN_UNREAD_MESSAGES:
      return {
        ...state,
      };
    case READ_USER_MESSAGES:
      return {
        ...state,
      };
    case READ_ADMIN_MESSAGES:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default messagesReducer;
