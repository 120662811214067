import React from "react";
// components
import CustomLabel from "components/customLabel/CustomLabel";
import ErrorText from "components/errorText/ErrorText";
import FormInput from "components/inputs/FormInput/FormInput";
import FormTextarea from "components/inputs/FormTextarea/FormTextarea";
import SelectInput from "components/inputs/SelectInput/SelectInput";
import FormInputandSelect from "components/inputs/FormInputandSelect/FormInputandSelect";
import SolutionRadio from "components/inputs/SolutionRadio/SolutionRadio";

const FormItem = ({ children, label, isRequired, err }) => {
  return (
    <section style={{ width: "100%" }}>
      <CustomLabel
        color={"#525c60"}
        highlightColor={isRequired ? "#0aaa9d" : null}
        name={label}
      />
      {children}
      {err && <ErrorText err={err} />}
    </section>
  );
};

const FormInputItem = ({
  label,
  isRequired,
  name,
  value,
  placeholder,
  handleChange,
  err,
  isDisabled,
  styles,
}) => {
  return (
    <FormItem label={label} isRequired={isRequired} err={err}>
      <FormInput
        inputId={name}
        inputName={name}
        inputValue={value}
        inputPlaceholder={placeholder}
        handleChange={handleChange}
        isDisabled={isDisabled ? isDisabled : null}
        styles={styles}
      />
    </FormItem>
  );
};

const FormTextareaItem = ({
  label,
  isRequired,
  name,
  value,
  placeholder,
  handleChange,
  err,
  isDisabled,
}) => {
  return (
    <FormItem label={label} isRequired={isRequired} err={err}>
      <FormTextarea
        textareaName={name}
        textareaValue={value}
        textareaPlaceholder={placeholder}
        handleChange={handleChange}
        isDisabled={isDisabled ? isDisabled : null}
      />
    </FormItem>
  );
};

const FormSelectItem = ({
  label,
  isRequired,
  name,
  value,
  placeholder,
  data,
  dataKeyParam,
  dataValueParam,
  dataOptionParam,
  handleChange,
  err,
}) => {
  return (
    <FormItem label={label} isRequired={isRequired} err={err}>
      <SelectInput
        selectName={name}
        selectValue={value}
        selectPlaceholder={placeholder}
        handleChange={handleChange}
        data={data}
        dataKeyParam={dataKeyParam}
        dataValueParam={dataValueParam}
        dataOptionParam={dataOptionParam}
      />
    </FormItem>
  );
};

const FormInputAndSelectItem = ({
  label,
  isRequired,
  err,
  name,
  value,
  placeholder,
  handleChange,
  data,
  matchingAttribute,
  setSelectedData,
}) => {
  return (
    <FormItem label={label} isRequired={isRequired} err={err}>
      <FormInputandSelect
        inputName={name}
        inputValue={value}
        inputPlaceholder={placeholder}
        isRequired={isRequired}
        handleChange={handleChange}
        existingData={data}
        matchingAttribute={matchingAttribute}
        setSelectedData={setSelectedData}
      />
    </FormItem>
  );
};

const FormCheckboxItem = ({
  name,
  value,
  content,
  description,
  handleChange,
  checked,
}) => {
  return (
    <SolutionRadio
      inputName={name}
      inputValue={value}
      content={content}
      description={description}
      onChangeFunction={handleChange}
      checked={checked}
    />
  );
};

export {
  FormItem,
  FormInputItem,
  FormTextareaItem,
  FormSelectItem,
  FormInputAndSelectItem,
  FormCheckboxItem,
};
