import React, { useEffect } from "react";
import style from "../../styles/createBudget.module.css";
// components
import { Button, Flex } from "@chakra-ui/react";
import Modal from "components/modal/Modal";
import FormCustomCheckbox from "components/inputs/FormCustomCheckbox/FormCustomCheckbox";
import { FormInputItem } from "components/formItems/FormItems";
// utils
import { useForm } from "hooks/useForm";

export default function CreateBudget({
  data,
  isOpen,
  closeModal,
  isMobile,
  backFunction,
  nextFunction,
}) {
  const services = [
    { name: "Identity" },
    { name: "Compare with other strain" },
    { name: "Characterization" },
    { name: "Security (Pathogenicity, Antibiotics Resistance)" },
    { name: "DNA Extraction" },
    { name: "Sequencing" },
  ];

  const selectedServices = data?.quotationsolutions?.[0]?.service || [];

  // form
  const initialForm = {
    services: selectedServices,
  };

  const validateForm = (form) => {
    let errors = {};

    if (form.services.length > 0) {
      form.services.forEach((service, index) => {
        if (!service.quantity) {
          errors.quantity = "Empty field";
        }
        if (service.price == 0) {
          errors.price = "Empty field";
        }
      });
    }

    return errors;
  };

  const {
    form,
    errors,
    response,
    send,
    handleChange,
    handleSubmit,
    resetForm,
  } = useForm(initialForm, validateForm);

  // check items manage
  const handleChangeCheckbox = (e) => {
    const { value, checked } = e.target;
    let updatedServices;

    if (checked) {
      updatedServices = [
        ...form.services,
        { name: value, quantity: "", price: "0" },
      ];
    } else {
      updatedServices = form.services.filter(
        (service) => service.name !== value
      );
    }

    handleChange({ target: { name: "services", value: updatedServices } });
  };

  const handleInputChange = (e, serviceName) => {
    const { name, value } = e.target;

    // Obtener el servicio actual del estado del formulario
    const serviceToUpdate = form.services.find(
      (service) => service.name === serviceName
    );

    // Crear un nuevo objeto de servicio actualizado con los campos actualizados
    const updatedService = {
      ...serviceToUpdate,
      [name]: value,
    };

    // Crear una copia del estado del formulario y reemplazar el servicio actualizado
    const updatedServices = form.services.map((service) => {
      if (service.name === serviceName) {
        return updatedService;
      }
      return service;
    });

    // Actualizar el estado del formulario con los servicios actualizados
    handleChange({ target: { name: "services", value: updatedServices } });
  };

  useEffect(() => {
    if (response) {
      nextFunction(form.services);
      resetForm();
    }
  }, [response]);

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      width={isMobile ? 330 : 700}
      height={"80vh"}
    >
      <Flex flexDirection={"column"} width={"90%"}>
        <Flex style={{ textTransform: "uppercase" }}>
          <h2 style={{ color: "#0AAA9D", fontSize: 18 }}>
            Quotation - #{data?.id}
          </h2>
        </Flex>
        <hr style={{ marginTop: 10, marginBottom: 20 }} />
        <Flex
          style={{
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <Flex flexDirection={"column"} width={"100%"}>
            <Flex
              style={{
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 10,
              }}
            >
              <Flex style={{ width: 200 }}>
                <h3 className={style.sectionName} style={{ paddingBottom: 10 }}>
                  Services
                </h3>
              </Flex>
              <Flex style={{ width: 350, gap: 15 }}>
                <Flex
                  style={{
                    width: 150,
                  }}
                >
                  <h3
                    className={style.sectionName}
                    style={{ paddingBottom: 10 }}
                  >
                    Quantity
                  </h3>
                </Flex>
                <Flex
                  style={{
                    width: 200,
                  }}
                >
                  <h3
                    className={style.sectionName}
                    style={{ paddingBottom: 10 }}
                  >
                    Price
                  </h3>
                </Flex>
              </Flex>
            </Flex>

            {services.map((service, index) => {
              const isSelected = selectedServices.some(
                (selectedService) => selectedService.name === service.name
              );

              const currentChecked = form.services.some(
                (checkedService) => checkedService.name === service.name
              );

              return (
                <Flex
                  key={index}
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Flex style={{ width: 200 }}>
                    <FormCustomCheckbox
                      inputName={"services"}
                      inputValue={service.name}
                      content={service.name}
                      onChangeFunction={
                        isSelected ? null : handleChangeCheckbox
                      }
                      disabled={isSelected}
                      defaultChecked={isSelected}
                    />
                  </Flex>
                  <Flex style={{ gap: 15, width: 350 }}>
                    <Flex>
                      <FormInputItem
                        label={""}
                        name={"quantity"}
                        value={
                          currentChecked
                            ? form.services.find((s) => s.name === service.name)
                                .quantity
                            : ""
                        }
                        placeholder={""}
                        isDisabled={currentChecked ? false : true}
                        handleChange={(e) => handleInputChange(e, service.name)}
                      />
                    </Flex>
                    <Flex>
                      <Flex alignItems={"center"} style={{ height: 64 }}>
                        <div
                          style={{
                            backgroundColor: "#CED4DA",
                            height: 48,
                            width: 60,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderTopLeftRadius: 5,
                            borderBottomLeftRadius: 5,
                          }}
                        >
                          <p>u$s</p>
                        </div>
                      </Flex>
                      <FormInputItem
                        label={""}
                        name={"price"}
                        value={
                          currentChecked
                            ? form.services.find((s) => s.name === service.name)
                                .price
                            : ""
                        }
                        placeholder={""}
                        isDisabled={currentChecked ? false : true}
                        handleChange={(e) => handleInputChange(e, service.name)}
                        styles={{
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                        }}
                      />
                    </Flex>
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
          <Flex gap={2.5} justifyContent={"flex-end"}>
            <Button onClick={backFunction}>Back</Button>
            <Button variant="brand" onClick={handleSubmit}>
              Send
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
}
