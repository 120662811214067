import React from "react";
import style from "./styles/solutionRadio.module.css";

export default function SolutionRadio({
  inputName,
  inputValue,
  content,
  description,
  onChangeFunction,
  checked
}) {
  return (
    <div className={style.contCheckbox}>
      <input
        type="radio"
        name={inputName}
        value={inputValue}
        onChange={(e) => onChangeFunction(e)}
        style={{ backgroundColor: "#fff" }}
        checked={checked}
      />
      <label className={style.customCheckbox}></label>
      <div className={style.text}>
        <span>{content}</span>
        <p>{description}</p>
      </div>
    </div>
  );
}
