export const columnsDataProjects = [
  {
    Header: "Created",
    accessor: "createTime",
  },
  {
    Header: "Project Name",
    accessor: "title",
  },
  {
    Header: "Company",
    accessor: "company.name",
  },
];

export default columnsDataProjects;
