import React from "react";
// components
import { FormLabel, Text } from "@chakra-ui/react";

export default function CustomLabel({ color, highlightColor, name }) {
  return (
    <FormLabel
      display="flex"
      mb="8px"
      fontSize="sm"
      fontWeight="500"
      color={color}
    >
      <span style={{ textTransform: "uppercase" }}>{name.charAt(0)}</span>
      {name.slice(1)}
      {highlightColor && <Text color={highlightColor}>*</Text>}
    </FormLabel>
  );
}
