import {
  CREATE_NEW_SAMPLE,
  GET_SAMPLES_BY_PROJECT_ID,
  DELETE_SAMPLE,
} from "redux/actions/actionTypes";

const initialState = {
  projectSamples: null,
};

const sampleReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NEW_SAMPLE:
      return {
        ...state,
      };
    case GET_SAMPLES_BY_PROJECT_ID:
      return {
        ...state,
        projectSamples: action.payload,
      };
    case DELETE_SAMPLE:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default sampleReducer;
