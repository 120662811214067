/////////////////////////LOCAL/////////////////////////

//export const web = "http://localhost:3000";
// export const server = "http://localhost:3001";

///////////////////TEST/////////////////////////

//export const web = "https://taxonapp.testeoenzo.online/";
//export const server = "https://taxonapp.testeoenzo.online/server";

/////////////////////////PROD/////////////////////////

export const web = "https://app.taxon.tech";
export const server = "https://app.taxon.tech/server";
