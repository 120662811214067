import React from "react";
// assets
import redirectIcon from "../../../../../../assets/img/icons/redirect.svg";
// components
import { Flex } from "@chakra-ui/react";

export default function UnableRedirectButton({ link }) {
  return (
    <Flex
      style={{
        cursor: "pointer",
      }}
    >
      <a href={`${link}`} target="_blank">
        <img src={redirectIcon} alt="Redirect - Icon" />
      </a>
    </Flex>
  );
}
