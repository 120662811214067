// cambiar el nombre de este componente
import React, { useState } from "react";
// components
import { FormInputItem } from "components/formItems/FormItems";
import { Flex } from "@chakra-ui/react";
import { FormTextareaItem } from "components/formItems/FormItems";
import { FormItem } from "components/formItems/FormItems";
import FormCustomCheckbox from "components/inputs/FormCustomCheckbox/FormCustomCheckbox";
import FormInput from "components/inputs/FormInput/FormInput";
import { FormInputAndSelectItem } from "components/formItems/FormItems";

const MicrobialCommunities = ({
  form,
  handleChange,
  userProjects,
  send,
  errors,
  selectedProject,
  setSelectedProject,
}) => {
  const [checkedServices, setCheckedServices] = useState([]);

  const handleChangeCheckbox = (e) => {
    const { value, checked } = e.target;
    let updatedServices;

    if (checked) {
      updatedServices = [...checkedServices, value];
    } else {
      updatedServices = checkedServices.filter((service) => service !== value);
    }

    setCheckedServices(updatedServices);

    const services = updatedServices.map((service) => ({ name: service }));
    handleChange({ target: { name: "services", value: services } });
  };

  const handleSampleChange = (index, field, value) => {
    const newSamples = form.samples.map((sample, i) =>
      i === index ? { ...sample, [field]: value } : sample
    );
    handleChange({ target: { name: "samples", value: newSamples } });
  };

  return (
    <div>
      <FormInputAndSelectItem
        label={"Project title"}
        isRequired={true}
        name={"projectName"}
        value={form.projectName}
        placeholder={""}
        handleChange={handleChange}
        data={userProjects}
        matchingAttribute={"title"}
        setSelectedData={setSelectedProject}
        err={null}
      />
      <FormTextareaItem
        label={"Project aim & details"}
        isDisabled={selectedProject ? true : false}
        isRequired={selectedProject ? false : true}
        name={"projectDescription"}
        value={form.projectDescription}
        placeholder={""}
        handleChange={handleChange}
        err={
          null /*send && errors.projectDescription ? errors.projectDescription : null*/
        }
      />
      <FormItem label={"Include"} err={null}>
        <Flex gap={10}>
          <FormCustomCheckbox
            inputName={"checkedService"}
            inputValue={"DNA Extraction"}
            content={"DNA Extraction"}
            onChangeFunction={handleChangeCheckbox}
          />
          <FormCustomCheckbox
            inputName={"checkedService"}
            inputValue={"Sequencing"}
            content={"Sequencing"}
            onChangeFunction={handleChangeCheckbox}
          />
        </Flex>
      </FormItem>
      <Flex style={{ justifyContent: "space-between", gap: 15 }}>
        <FormInputItem
          label={"Number of Samples"}
          name={"numberOfSamples"}
          value={form.numberOfSamples}
          placeholder={""}
          handleChange={(e) =>
            handleSampleChange(0, "numberOfSamples", e.target.value)
          }
          err={
            null /*send && errors.numberOfSamples ? errors.numberOfSamples : null*/
          }
        />
        <FormInputItem
          label={"Sample origin"}
          name={"sampleOrigin"}
          value={form.sampleOrigin}
          placeholder={""}
          handleChange={(e) =>
            handleSampleChange(0, "sampleOrigin", e.target.value)
          }
          err={
            null /*send && errors.sampleOrigin ? errors.sampleOrigin : null*/
          }
        />
      </Flex>
      <FormInputItem
        label={"Direct URL to your samples"}
        isRequired={true}
        name={"sampleURL"}
        value={form.sampleURL}
        placeholder={""}
        handleChange={(e) => handleSampleChange(0, "sampleURL", e.target.value)}
        err={null /*send && errors.sampleURL ? errors.sampleURL : null*/}
      />
    </div>
  );
};

const SingleStrains = ({
  form,
  handleChange,
  userProjects,
  send,
  errors,
  selectedProject,
  setSelectedProject,
}) => {
  const [checkedServices, setCheckedServices] = useState([]);

  const handleChangeCheckbox = (e) => {
    const { value, checked } = e.target;
    let updatedServices;

    if (checked) {
      updatedServices = [...checkedServices, value];
    } else {
      updatedServices = checkedServices.filter((service) => service !== value);
    }

    setCheckedServices(updatedServices);

    const services = updatedServices.map((service) => ({ name: service }));
    handleChange({ target: { name: "services", value: services } });
  };

  const handleSampleChange = (index, field, value) => {
    const newSamples = [...form.samples];

    // Ensure the array has an object at the given index
    if (!newSamples[index]) {
      newSamples[index] = {};
    }

    // Update the field of the sample at the given index
    newSamples[index][field] = value;

    // If all fields of the sample are empty, remove it from the array
    if (
      !newSamples[index].numberOfSamples &&
      !newSamples[index].sampleOrigin &&
      !newSamples[index].type
    ) {
      newSamples[index] = {
        numberOfSamples: "",
        sampleOrigin: "",
      };
    }

    handleChange({ target: { name: "samples", value: newSamples } });
  };

  return (
    <>
      <div>
        <FormInputAndSelectItem
          label={"Project title"}
          isRequired={true}
          name={"projectName"}
          value={form.projectName}
          placeholder={""}
          handleChange={handleChange}
          data={userProjects}
          matchingAttribute={"title"}
          setSelectedData={setSelectedProject}
          err={null}
        />
        <FormTextareaItem
          label={"Project aim & details"}
          isDisabled={selectedProject ? true : false}
          isRequired={selectedProject ? false : true}
          name={"projectDescription"}
          value={form.projectDescription}
          placeholder={""}
          handleChange={handleChange}
          err={
            null /*send && errors.projectDescription ? errors.projectDescription : null*/
          }
        />
        <FormItem
          label={"What do you want to know about your sample?"}
          err={null}
        >
          <Flex justifyContent={"space-between"}>
            <FormCustomCheckbox
              inputName={"checkedService"}
              inputValue={"Identity"}
              content={"Identity"}
              onChangeFunction={handleChangeCheckbox}
            />
            <FormCustomCheckbox
              inputName={"checkedService"}
              inputValue={"Compare with other strain"}
              content={"Compare with other strain"}
              onChangeFunction={handleChangeCheckbox}
            />
            <FormCustomCheckbox
              inputName={"checkedService"}
              inputValue={"Characterization"}
              content={"Characterization"}
              onChangeFunction={handleChangeCheckbox}
            />
          </Flex>
          <Flex style={{ marginTop: -20 }}>
            <FormCustomCheckbox
              inputName={"checkedService"}
              inputValue={"Security (Pathogenicity, Antibiotics Resistance)"}
              content={"Security (Pathogenicity, Antibiotics Resistance)"}
              onChangeFunction={handleChangeCheckbox}
            />
          </Flex>
        </FormItem>
        <FormItem label={"Include"} err={null}>
          <Flex gap={10}>
            <FormCustomCheckbox
              inputName={"checkedService"}
              inputValue={"DNA Extraction"}
              content={"DNA Extraction"}
              onChangeFunction={handleChangeCheckbox}
            />
            <FormCustomCheckbox
              inputName={"checkedService"}
              inputValue={"Sequencing"}
              content={"Sequencing"}
              onChangeFunction={handleChangeCheckbox}
            />
          </Flex>
        </FormItem>
        <FormItem label={"Samples"} err={null}>
          <section
            className="Prokaryote"
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <Flex style={{ width: 110 }}>
              <FormCustomCheckbox
                inputName={"sample"}
                inputValue={"Prokaryote"}
                content={"Prokaryote"}
                onChangeFunction={(e) =>
                  handleSampleChange(
                    0,
                    "type",
                    e.target.checked ? "Prokaryote" : ""
                  )
                }
              />
            </Flex>
            <Flex gap={2.5}>
              <FormInput
                inputName={"prokaryoteNumber"}
                inputValue={form.numberOfSamples}
                inputPlaceholder={"Number of samples"}
                handleChange={(e) =>
                  handleSampleChange(0, "numberOfSamples", e.target.value)
                }
              />
              <FormInput
                inputName={"prokaryoteOrigin"}
                inputValue={form.sampleOrigin}
                inputPlaceholder={"Sample origin"}
                handleChange={(e) =>
                  handleSampleChange(0, "sampleOrigin", e.target.value)
                }
              />
            </Flex>
          </section>
          <section
            className="Eucaryote"
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <Flex style={{ width: 110 }}>
              <FormCustomCheckbox
                inputName={"sample"}
                inputValue={"Eucaryote"}
                content={"Eucaryote"}
                onChangeFunction={(e) =>
                  handleSampleChange(
                    1,
                    "type",
                    e.target.checked ? "Eucaryote" : ""
                  )
                }
              />
            </Flex>
            <Flex gap={2.5}>
              <FormInput
                inputName={"eucaryoteNumber"}
                inputValue={form.numberOfSamples}
                inputPlaceholder={"Number of samples"}
                handleChange={(e) =>
                  handleSampleChange(1, "numberOfSamples", e.target.value)
                }
              />
              <FormInput
                inputName={"eucaryoteOrigin"}
                inputValue={form.sampleOrigin}
                inputPlaceholder={"Sample origin"}
                handleChange={(e) =>
                  handleSampleChange(1, "sampleOrigin", e.target.value)
                }
              />
            </Flex>
          </section>
        </FormItem>
        <FormInputItem
          label={"Direct URL to your samples"}
          isRequired={true}
          name={"sampleURL"}
          value={form.sampleURL}
          placeholder={""}
          handleChange={(e) =>
            handleSampleChange(0, "sampleURL", e.target.value)
          }
          err={null /*send && errors.sampleURL ? errors.sampleURL : null*/}
        />
      </div>
    </>
  );
};

const Customized = ({
  form,
  handleChange,
  userProjects,
  send,
  errors,
  selectedProject,
  setSelectedProject,
}) => {
  const handleSampleChange = (index, field, value) => {
    const newSamples = form.samples.map((sample, i) =>
      i === index ? { ...sample, [field]: value } : sample
    );
    handleChange({ target: { name: "samples", value: newSamples } });
  };

  return (
    <div>
      <FormInputAndSelectItem
        label={"Project title"}
        isRequired={true}
        name={"projectName"}
        value={form.projectName}
        placeholder={""}
        handleChange={handleChange}
        data={userProjects}
        matchingAttribute={"title"}
        setSelectedData={setSelectedProject}
        err={null}
      />
      <FormTextareaItem
        label={"Project aim & details"}
        isDisabled={selectedProject ? true : false}
        isRequired={selectedProject ? false : true}
        name={"projectDescription"}
        value={form.projectDescription}
        placeholder={""}
        handleChange={handleChange}
        err={
          null /*send && errors.projectDescription ? errors.projectDescription : null*/
        }
      />
      <Flex style={{ justifyContent: "space-between", gap: 15 }}>
        <FormInputItem
          label={"Number of Samples"}
          name={"numberOfSamples"}
          value={form.numberOfSamples}
          placeholder={""}
          handleChange={(e) =>
            handleSampleChange(0, "numberOfSamples", e.target.value)
          }
          err={
            null /*send && errors.numberOfSamples ? errors.numberOfSamples : null*/
          }
        />
        <FormInputItem
          label={"Sample origin"}
          name={"sampleOrigin"}
          value={form.sampleOrigin}
          placeholder={""}
          handleChange={(e) =>
            handleSampleChange(0, "sampleOrigin", e.target.value)
          }
          err={
            null /*send && errors.sampleOrigin ? errors.sampleOrigin : null*/
          }
        />
      </Flex>
      <FormInputItem
        label={"Direct URL to your samples"}
        isRequired={true}
        name={"sampleURL"}
        value={form.sampleURL}
        placeholder={""}
        handleChange={(e) => handleSampleChange(0, "sampleURL", e.target.value)}
        err={null /*send && errors.sampleURL ? errors.sampleURL : null*/}
      />
    </div>
  );
};

export { MicrobialCommunities, SingleStrains, Customized };
