import {
  CREATE_NEW_RESULT,
  DELETE_RESULT,
  GET_RESULTS_BY_PROJECT_ID,
} from "redux/actions/actionTypes";

const initialState = {
  projectResults: null,
};

const resultReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NEW_RESULT:
      return {
        ...state,
      };
    case GET_RESULTS_BY_PROJECT_ID:
      return {
        ...state,
        projectResults: action.payload,
      };
    case DELETE_RESULT:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default resultReducer;
