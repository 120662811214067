import React from "react";
import style from "../styles/quotationDetail.module.css";
// assets
import checkbox from "../../../../assets/img/icons/check.png";
// components
import { Flex } from "@chakra-ui/react";
import FormInput from "components/inputs/FormInput/FormInput";

const Item = ({ data }) => {
  return (
    <Flex key={data.id} alignItems={"center"} justifyContent={"space-between"}>
      <Flex gap={2.5} alignItems={"center"}>
        <img
          src={checkbox}
          alt="Check Item"
          style={{ width: 14, height: 14 }}
        />
        <p className={style.itemName}>{data.solution.name}</p>
      </Flex>
    </Flex>
  );
};

export default function Services({ data }) {
  return (
    <Flex flexDirection={"column"} width={"100%"}>
      <h3 className={style.title}>Services</h3>
      <Flex flexDirection={"column"} gap={2.5} mb={5}>
        {data.quotationsolutions.map((solution) => (
          <Item data={solution} />
        ))}
      </Flex>
    </Flex>
  );
}
