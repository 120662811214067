import React from "react";
// components
import { Checkbox } from "@chakra-ui/react";

export default function FormCheckbox({
  inputId,
  inputName,
  inputValue,
  handleChange,
}) {
  return (
    <Checkbox
      id={inputId}
      name={inputName ? inputName : ""}
      value={inputValue ? inputValue : ""}
      onChange={handleChange ? handleChange : null}
      checked={null}
      colorScheme="brand"
      me="10px"
      style={{ backgroundColor: "#fff" }}
    />
  );
}
