import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import style from "../../../../styles/form.module.css";
// actions
import { updateUser } from "redux/actions/user";
import { createNewCompany } from "redux/actions/company";
import { createNewProject } from "redux/actions/project";
import { createNewQuotation } from "redux/actions/quotation";
import { createNewSample } from "redux/actions/samples";
import { getUserQuotations } from "redux/actions/quotation";
// components
import { Flex } from "@chakra-ui/react";
// utils
import { useForm } from "hooks/useForm";
import PersonalSection from "./Steps/PersonalSection";
import SolutionSection from "./Steps/SolutionSection";
import ProjectSection from "./Steps/ProjectSection";

export default function Form({
  currentStep,
  setCurrentStep,
  user,
  userCompanies,
  userProjects,
  closeModal,
}) {
  const dispatch = useDispatch();
  const initialForm = {
    companyName: "",
    companyPosition: "",
    userName: "",
    userEmail: "",
    userPhone: "",
    solution: "",
    projectName: "",
    projectDescription: "",
    services: [],
    samples: [
      {
        numberOfSamples: "",
        sampleOrigin: "",
        sampleURL: "",
      },
      {
        numberOfSamples: "",
        sampleOrigin: "",
        sampleURL: "",
      },
    ],
  };

  const validateForm = (form) => {
    let errors = {};
    if (form.companyName.trim() === "") {
      errors.companyName = "Empty field";
    }

    if (!selectedCompany && form.companyPosition.trim() === "") {
      errors.companyPosition = "Empty field";
    }

    if (form.solution.trim() === "") {
      errors.solution = "Choose a solution";
    }

    if (form.projectName.trim() === "") {
      errors.projectName = "Empty field";
    }
    if (!selectedProject && form.projectDescription.trim() === "") {
      errors.projectDescription = "Empty field";
    }

    if (
      form.samples[0].numberOfSamples &&
      isNaN(form.samples[0].numberOfSamples)
    ) {
      errors.numberOfSamples = "Must be a number";
    }

    if (
      form.samples[1].numberOfSamples &&
      isNaN(form.samples[1].numberOfSamples)
    ) {
      errors.numberOfSamples = "Must be a number";
    }

    if (form.samples[0].sampleURL.trim() === "") {
      errors.sampleURL = "Empty field";
    }

    return errors;
  };

  const {
    form,
    errors,
    response,
    send,
    handleChange,
    handleSubmit,
    resetForm,
  } = useForm(initialForm, validateForm);

  const skipFunction = () => {
    closeModal();
    resetForm();
    setCurrentStep(1);
  };

  const [selectedCompany, setSelectedCompany] = useState(false);
  let currentCompany;
  if (selectedCompany) {
    currentCompany = userCompanies.find(
      (company) => company.name === form.companyName
    );
  }

  const [selectedProject, setSelectedProject] = useState(false);
  let currentCompanyProjects;
  if (selectedCompany && currentCompany) {
    currentCompanyProjects = userProjects.filter(
      (project) => project.company.id == currentCompany.id
    );
  }

  useEffect(() => {
    if (response) {
      // checks if userPhone has some value
      if (form.userPhone) {
        dispatch(updateUser(user.id, { phone: form.userPhone })).catch(
          function (err) {
            console.log(err);
          }
        );
      }

      // manage the company
      let currentCompanyPromise;
      // check if user have that company
      if (selectedCompany) {
        currentCompanyPromise = Promise.resolve(
          userCompanies.find((company) => company.name === form.companyName)
        );
      }
      // else, create it
      else {
        currentCompanyPromise = dispatch(
          createNewCompany({
            name: form.companyName,
            position: form.companyPosition,
            user: { id: user.id },
          })
        )
          .then(function (res) {
            return res;
          })
          .catch(function (err) {
            console.log(err);
          });
      }

      currentCompanyPromise.then((currentCompany) => {
        // manage the project
        let currentProjectPromise;
        // check if user have that project
        if (selectedProject) {
          currentProjectPromise = Promise.resolve(
            userProjects.find((project) => project.title === form.projectName)
          );
        }
        // else, create it
        else {
          currentProjectPromise = currentCompany
            ? dispatch(
                createNewProject({
                  title: form.projectName,
                  details: form.projectDescription,
                  user: { id: user.id },
                  company: { id: currentCompany.id },
                })
              )
            : Promise.reject("Missed company to project creation");
        }

        currentProjectPromise.then((currentProject) => {
          if (currentCompany && currentProject) {
            dispatch(
              createNewQuotation({
                project: { id: currentProject.id },
                solution: { id: Number(form.solution) },
                services: form.services,
              })
            )
              .then(function (res) {
                // res.id = quotationId
                // res.project.id = projectId

                form.samples.forEach((sample, index) => {
                  // Verificar si hay información en sampleURL para cada muestra
                  if (
                    sample.sampleURL.trim() !== "" ||
                    sample.type.trim() !== ""
                  ) {
                    // Solo enviar la solicitud al backend si tienes una sampleURL válida para cada muestra
                    dispatch(
                      createNewSample({
                        link: sample.sampleURL ? sample.sampleURL : "-",
                        numberOfSamples: sample.numberOfSamples
                          ? sample.numberOfSamples
                          : 0,
                        sampleOrigin: sample.sampleOrigin
                          ? sample.sampleOrigin
                          : "-",
                        type: sample.type ? sample.type : null,
                        description: `Sample of Quotation #${res.id}`,
                        project: { id: currentProject.id },
                        quotation: { id: res.id },
                      })
                    )
                      .then(function () {
                        skipFunction();
                        dispatch(getUserQuotations(user.id));
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }
                });
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
      });
    }
  }, [response]);

  return (
    <Flex className={style.formSection}>
      {currentStep === 1 ? (
        <PersonalSection
          form={form}
          handleChange={handleChange}
          changeView={setCurrentStep}
          closeModal={skipFunction}
          errors={errors}
          user={user}
          userCompanies={userCompanies}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
        />
      ) : currentStep === 2 ? (
        <SolutionSection
          form={form}
          handleChange={handleChange}
          changeView={setCurrentStep}
          closeModal={skipFunction}
          errors={errors}
        />
      ) : (
        <ProjectSection
          solutionId={form.solution}
          userProjects={
            !selectedCompany
              ? []
              : currentCompanyProjects
              ? currentCompanyProjects
              : []
          }
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
          form={form}
          handleChange={handleChange}
          changeView={setCurrentStep}
          closeModal={skipFunction}
          errors={errors}
          handleSubmit={handleSubmit}
        />
      )}
    </Flex>
  );
}
