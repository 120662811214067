import {
  ACCEPT_QUOTATION_MESSAGE,
  CLEAN_MESSAGE,
  CLEAR_USER_STATE,
  GET_ALL_USERS,
  GET_USER_BY_USER_ID,
  RESET_PASSWORD,
  UPDATE_USER,
  USER_LOGIN,
  USER_LOGIN_WITH_GOOGLE,
  USER_LOGOUT,
  USER_REGISTER,
  USER_VERIFY_WITH_GOOGLE,
} from "../../actions/actionTypes";

const initialState = {
  allUsers: null,
  // keep one user info --> admin view
  userInfo: null,
  // keep logged user info
  currentUser: null,
  currentToken: null,
  recentlyLogged: null,
  // to manage the accept quotation provisory
  acceptMessage: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACCEPT_QUOTATION_MESSAGE:
      return {
        ...state,
        acceptMessage: action.payload,
      };
    case CLEAN_MESSAGE:
      return {
        ...state,
        acceptMessage: null,
      };
    case GET_ALL_USERS:
      return {
        ...state,
        allUsers: action.payload,
      };
    case GET_USER_BY_USER_ID:
      return {
        ...state,
        userInfo: action.payload,
      };
    case RESET_PASSWORD:
      return {
        ...state,
      };
    case USER_REGISTER:
      return {
        ...state,
        currentUser: action.payload,
        currentToken: action.token,
        recentlyLogged: true,
      };
    case USER_VERIFY_WITH_GOOGLE:
      return {
        ...state,
      };
    case USER_LOGIN_WITH_GOOGLE:
      return {
        ...state,
        currentUser: action.payload,
        currentToken: action.token,
        recentlyLogged: true,
      };
    case USER_LOGIN:
      return {
        ...state,
        currentUser: action.payload,
        currentToken: action.token,
        recentlyLogged: true,
      };
    case USER_LOGOUT:
      return {
        ...state,
        currentUser: null,
        currentToken: null,
      };
    case CLEAR_USER_STATE:
      return {
        ...state,
        recentlyLogged: null,
      };
    case UPDATE_USER:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default userReducer;
