import React from "react";
// components
import { Button } from "@chakra-ui/react";

export default function QuoteButton({ quoteFunction }) {
  return (
    <Button variant="brand" onClick={() => quoteFunction()}>
      Quote
    </Button>
  );
}
