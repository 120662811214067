import React from "react";
import Layout from "../../../layouts/auth";
// components
import { Flex } from "@chakra-ui/react";
import AuthHeader from "components/authHeader/AuthHeader";
import RegisterForm from "./sections/RegisterForm/RegisterForm";
// utils
import { useResponsive } from "hooks/useResponsive";

export default function Register() {
  const isMobile = useResponsive();

  return (
    <Layout>
      <Flex
        borderRadius={10}
        padding={10}
        backgroundColor={"white"}
        flexDirection={"column"}
      >
        <AuthHeader
          title={"Registration"}
          caption={"Enter your information to register on the site!"}
          isMobile={isMobile}
        />
        <RegisterForm isMobile={isMobile}/>
      </Flex>
    </Layout>
  );
}
