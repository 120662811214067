import React from "react";
// components
import { Flex } from "@chakra-ui/react";
import RedirectButton from "../buttons/RedirectButton";
import ChatButton from "../buttons/ChatButton";

export default function ReadyActions({ data }) {
  return (
    <Flex gap={10} alignItems={"center"}>
      <RedirectButton link={data[0].link} />
      <ChatButton />
    </Flex>
  );
}
