import { Flex } from "@chakra-ui/react";

export const Logo = ({ isMobile }) => {
  return (
    <Flex
      style={{
        backgroundColor: "white",
        width: "100%",
        height: isMobile ? 80 : 120,
      }}
    >
      <Flex style={{ width: "100%", padding: isMobile ? 30 : 40 }}>
        <img
          src={
            "https://res.cloudinary.com/dsxfufacm/image/upload/v1692723758/Taxon/logo-taxon_mheico.svg"
          }
          alt="Logo Taxon"
          style={{ width: isMobile ? 105 : 140 }}
        />
      </Flex>
    </Flex>
  );
};
