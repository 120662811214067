import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "./styles/chat.module.css";
// actions
import { getUserMessages } from "redux/actions/messages";
// components
import { Box, Flex } from "@chakra-ui/react";
import Loader from "components/loader/Loader";
import Conversations from "./sections/Conversations/Conversations";
import Messages from "./sections/Messages/Messages";

export default function Chat() {
  const user = useSelector((state) => state.user.currentUser);
  const data = useSelector((state) => state.message.currentMessages);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserMessages(user.id))
      .then(function () {
        setIsLoading(false);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [user]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Flex pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex className={style.container}>
        <Box w="100%" color="black" borderRadius={20}>
          <Flex justifyContent={"space-between"} style={{ width: "100%" }}>
            <Conversations user={user} />
            <Messages user={user} data={data} />
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
}
