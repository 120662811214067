import React from "react";
import style from "../../styles/quotations.module.css";
// components
import { Box, Flex } from "@chakra-ui/react";
import Card from "components/card/Card";
import OrderTable from "../../components/OrderTable/OrderTable";
import BoxError from "components/boxError/BoxError";
// variables
import columnsDataDevelopment from "../../variables/columnDataOrders";

export default function Orders({ data }) {
  return (
    <Card>
      <Flex flexDirection={"column"}>
        <Box bg="white" w="100%" p={4} color="black" borderRadius={20}>
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <h2 className={style.title}>Orders</h2>
          </Flex>
        </Box>
        {data && data[0] !== undefined ? (
          <OrderTable
            columnsData={columnsDataDevelopment}
            tableData={data}
          />
        ) : (
          <BoxError message={"There aren't any orders"} />
        )}
      </Flex>
    </Card>
  );
}
