import axiosInstance from "constants/axiosInstance";
import {
  CREATE_NEW_COMPANY,
  GET_COMPANIES_BY_USER_ID,
  GET_COMPANY,
} from "../actionTypes";

export function createNewCompany(data) {
  return async (dispatch) => {
    try {
      const newCompany = await axiosInstance.post(`/companies`, data);
      dispatch({
        type: CREATE_NEW_COMPANY,
      });
      return newCompany.data;
    } catch (error) {
      return error;
    }
  };
}

export function getUserCompanies(userId) {
  return async (dispatch) => {
    try {
      const companies = await axiosInstance.get(`/companies/user/${userId}`);
      dispatch({
        type: GET_COMPANIES_BY_USER_ID,
        payload: companies.data,
      });
      return companies.data;
    } catch (error) {
      return error;
    }
  };
}

export function getCompany(companyId) {
  return async (dispatch) => {
    try {
      const company = await axiosInstance.get(`/companies/${companyId}`);
      dispatch({
        type: GET_COMPANY,
        payload: company.data,
      });
      return company.data;
    } catch (error) {
      return error;
    }
  };
}
