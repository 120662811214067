import React from "react";
// components
import { Select } from "@chakra-ui/react";

export default function SelectInput({
  selectName,
  selectValue,
  selectPlaceholder,
  handleChange,
  data,
  dataKeyParam,
  dataValueParam,
  dataOptionParam,
}) {
  return (
    <Select
      name={selectName}
      value={selectValue}
      placeholder={selectPlaceholder}
      onChange={handleChange}
      mb="24px"
      fontSize="sm"
      fontWeight="500"
      borderRadius={5}
      style={{ backgroundColor: "#fff" }}
    >
      {data &&
        data.length !== 0 &&
        data.map((el) => (
          <option key={el[dataKeyParam]} value={el[dataValueParam]}>
            {el[dataOptionParam]}
          </option>
        ))}
    </Select>
  );
}
