import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getUserCompanies } from "redux/actions/company";

// unificar?
const useUserCompanies = (userId) => {
  const dispatch = useDispatch();
  const [companies, setCompanies] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCompanies = () => {
      try {
        dispatch(getUserCompanies(userId))
          .then((data) => setCompanies(data))
          .catch((err) => console.log(err))
          .finally(() => setIsLoading(false));
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    };

    fetchCompanies();
  }, [userId]);

  return { companies, isLoading };
};

export default useUserCompanies;
