import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
// actions
import { createNewProject } from "redux/actions/project";
import { getProjects } from "redux/actions/project";
// components
import ModalForm from "components/modal/ModalForm/ModalForm";
import { Flex } from "@chakra-ui/react";
import {
  FormInputItem,
  FormSelectItem,
  FormTextareaItem,
} from "components/formItems/FormItems";
import GrayButton from "components/buttons/GrayButton";
import GreenButton from "components/buttons/GreenButton";
// utils
import { useForm } from "hooks/useForm";

export default function CreateProjectModal({
  isOpen,
  closeModal,
  isMobile,
  userCompanies,
  userId,
}) {
  const dispatch = useDispatch();

  const initialForm = {
    title: "",
    details: "",
    user: { id: userId },
    company: "",
  };

  const validateForm = (form) => {
    let errors = {};

    if (form.company === "") {
      errors.company = "You haven't selected any option";
    }
    if (form.title === "") {
      errors.title = "Empty field";
    }
    if (form.details === "") {
      errors.details = "Empty field";
    } else if (form.details.length > 255) {
      errors.details = "Excess characters. Must be at most 255 characters";
    }

    return errors;
  };

  const {
    form,
    errors,
    response,
    send,
    handleChange,
    handleSubmit,
    resetForm,
  } = useForm(initialForm, validateForm);

  useEffect(() => {
    if (response) {
      createProject({ ...form, company: { id: form.company } });
    }
  }, [response]);

  const createProject = (data) => {
    dispatch(createNewProject(data))
      .catch((err) => console.log(err))
      .finally(() => restoreForm());
  };

  const restoreForm = () => {
    resetForm();
    closeModal();
    dispatch(getProjects(userId));
  };

  return (
    <ModalForm
      isOpen={isOpen}
      closeModal={closeModal}
      width={!isMobile && 518}
      height={!isMobile && 560}
      header={"New Project"}
    >
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <FormSelectItem
          label={"Company"}
          isRequired={true}
          name={"company"}
          value={form.company}
          placeholder={"Select company"}
          data={userCompanies}
          dataKeyParam={"id"}
          dataValueParam={"id"}
          dataOptionParam={"name"}
          handleChange={handleChange}
          err={send && errors.company ? errors.company : null}
        />
        <FormInputItem
          label={"Project title"}
          isRequired={true}
          name={"title"}
          value={form.title}
          placeholder={""}
          handleChange={handleChange}
          err={send && errors.title ? errors.title : null}
        />
        <FormTextareaItem
          label={"Project aim & details"}
          isRequired={true}
          name={"details"}
          value={form.details}
          placeholder={""}
          handleChange={handleChange}
          err={send && errors.details ? errors.details : null}
        />
        <Flex justifyContent={"flex-end"} gap={5} mb={5}>
          <GrayButton text={"Cancel"} onClick={closeModal} />
          <GreenButton text={"Create"} onClick={handleSubmit} />
        </Flex>
      </form>
    </ModalForm>
  );
}
