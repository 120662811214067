import React from "react";
// assets
import editIcon from "../../../../../assets/img/icons/edit.svg";
// components
import { Flex } from "@chakra-ui/react";
import EditProject from "components/modal/EditProject/EditProject";
// utils
import { useModal } from "hooks/useModal";

export default function EditButton({ data, isMobile }) {
  const [isOpen, openModal, closeModal, setIsOpen] = useModal(false);

  return (
    <>
      <Flex style={{ cursor: "pointer" }} onClick={() => setIsOpen(true)}>
        <img src={editIcon} alt="Edit project - Icon" />
      </Flex>
      {data && (
        <EditProject
          id={data.id}
          data={data}
          isOpen={isOpen}
          closeModal={closeModal}
          setIsOpen={setIsOpen}
          isMobile={isMobile}
        />
      )}
    </>
  );
}
