import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// actions
import { getCompany } from "redux/actions/company";
// components
import { Button, Flex } from "@chakra-ui/react";
import Modal from "components/modal/Modal";
import CustomLabel from "components/customLabel/CustomLabel";
import FormInput from "components/inputs/FormInput/FormInput";

export default function DetailCompany({ isOpen, closeModal, isMobile, id }) {
  const dispatch = useDispatch();
  const [company, setCompany] = useState(null);

  useEffect(() => {
    dispatch(getCompany(id))
      .then(function (res) {
        setCompany(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [id]);

  if (!company) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      width={isMobile ? 320 : 518}
      height={425}
    >
      <Flex flexDirection={"column"} width={"90%"}>
        <Flex
          className="title"
          style={{
            textTransform: "uppercase",
          }}
        >
          <h2 style={{ color: "#0AAA9D", fontSize: 18, fontWeight: 600 }}>
            {company.name}
          </h2>
        </Flex>
        <hr style={{ marginTop: 10, marginBottom: 10 }} />
        <form>
          <Flex gap={2.5}>
            <section style={{ width: "100%" }}>
              <CustomLabel
                color={"#525c60"}
                highlightColor={"#0aaa9d"}
                name={"Position"}
              />
              <FormInput
                inputId={"position"}
                inputName={"position"}
                inputValue={company.position}
                inputPlaceholder={company.position}
                isDisabled={true}
              />
            </section>
            <section style={{ width: "100%" }}>
              <CustomLabel
                color={"#525c60"}
                highlightColor={"#0aaa9d"}
                name={"Department"}
              />
              <FormInput
                inputId={"department"}
                inputName={"department"}
                inputValue={company.department}
                inputPlaceholder={company.department}
                isDisabled={true}
              />
            </section>
          </Flex>
          <section style={{ width: "100%" }}>
            <CustomLabel color={"#525c60"} name={"Street"} />
            <FormInput
              inputId={"street"}
              inputName={"street"}
              inputValue={company.street}
              inputPlaceholder={company.street}
              isDisabled={true}
            />
          </section>
          <Flex gap={2.5}>
            <section style={{ width: "100%" }}>
              <CustomLabel
                color={"#525c60"}
                highlightColor={"#0aaa9d"}
                name={"City"}
              />
              <FormInput
                inputId={"city"}
                inputName={"city"}
                inputValue={company.city}
                inputPlaceholder={company.city}
                isDisabled={true}
              />
            </section>
            <section style={{ width: "100%" }}>
              <CustomLabel
                color={"#525c60"}
                highlightColor={"#0aaa9d"}
                name={"Zipcode"}
              />
              <FormInput
                inputId={"zipcode"}
                inputName={"zipcode"}
                inputValue={company.zipcode}
                inputPlaceholder={company.zipcode}
                isDisabled={true}
              />
            </section>
          </Flex>
          <Flex justifyContent={"flex-end"} mb={5}>
            <Button onClick={closeModal}>Cancel</Button>
          </Flex>
        </form>
      </Flex>
    </Modal>
  );
}
