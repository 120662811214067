import React from "react";
// assets
import uploadIcon from "../../../../../../assets/img/icons/upload.svg";
// components
import { Flex } from "@chakra-ui/react";

export default function UnableUploadButton() {
  return (
    <Flex
      style={{
        cursor: "pointer",
        filter: "grayscale(100%)",
        opacity: "30%",
        pointerEvents: "none",
      }}
    >
      <img src={uploadIcon} alt="Upload Result - Icon" />
    </Flex>
  );
}
