import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import style from "./styles/newService.module.css";
// actions
import { getUserQuotations } from "redux/actions/quotation";
import { getUserOrders } from "redux/actions/quotation";
import { getUserCompanies } from "redux/actions/company";
import { getProjects } from "redux/actions/project";
// components
import { Flex } from "@chakra-ui/react";
import Quotations from "./sections/Quotations/Quotations";
import Orders from "./sections/Orders/Orders";
import Loader from "components/loader/Loader";
// utils
import { useResponsive } from "hooks/useResponsive";
import CreateQuotation from "./sections/CreateQuotation/CreateQuotation";

export default function NewService() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const token = useSelector((state) => state.user.currentToken);
  const companies = useSelector((state) => state.company.allUserCompanies);
  const projects = useSelector((state) => state.project.allUserProjects);
  const quotations = useSelector((state) => state.quotation.userQuotations);
  const orders = useSelector((state) => state.quotation.userOrders);
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = useResponsive();

  useEffect(() => {
    if (token) {
      dispatch(getUserCompanies(user.id))
        .then(function () {
          dispatch(getUserQuotations(user.id))
            .then(function () {
              dispatch(getUserOrders(user.id))
                .then(function () {
                  dispatch(getProjects(user.id))
                    .then(function () {
                      setIsLoading(false);
                    })
                    .catch(function (err) {
                      console.log(err);
                    });
                })
                .catch(function (err) {
                  console.log(err);
                });
            })
            .catch(function (err) {
              console.log(err);
            });
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }, [user, token]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Flex
      pt={{ base: "130px", md: "80px", xl: "80px" }}
      className={style.container}
    >
      <CreateQuotation
        user={user}
        userCompanies={companies}
        userProjects={projects}
      />
      <Quotations data={quotations} isMobile={isMobile} />
      <Orders data={orders} />
    </Flex>
  );
}
