import React from "react";
import style from "../../styles/information.module.css";
// components
import { Box, Flex } from "@chakra-ui/react";
import Card from "components/card/Card";

export default function Information({ data }) {
  return (
    <Flex flexDirection={"column"} gap={2.5} style={{ marginTop: 30 }}>
      <h2 className={style.title}>{data.title}</h2>
      <Card>
        <Flex flexDirection={"column"} gap={5}>
          <Box
            className={style.contentContainer}
            bg="white"
            w="100%"
            p={4}
            color="black"
            borderRadius={20}
          >
            <Flex gap={"50%"}>
              <div className={style.section}>
                <h4>User</h4>
                <p>{data.user.name}</p>
              </div>
              <div className={style.section}>
                <h4>Company</h4>
                <p>{data.company.name}</p>
              </div>
            </Flex>
            <div className={style.section}>
              <h4>Description</h4>
              <p>{data.details}</p>
            </div>
          </Box>
        </Flex>
      </Card>
    </Flex>
  );
}
