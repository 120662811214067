import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// assets
import eyeIcon from "../../../../../../assets/img/icons/eye.svg";
// components
import { Flex } from "@chakra-ui/react";
import BudgetResume from "../../BudgetResume/BudgetResume";
import ConfirmAction from "components/modal/ConfirmAction/ConfirmAction";
// utils
import { useModal } from "hooks/useModal";
import { acceptQuotationMessage } from "redux/actions/user";

export default function BudgetDetailButton({ id, isMobile }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isOpen, openModal, closeModal, setIsOpen] = useModal(false);
  const [isOpenConfirm, openModalConfirm, closeModalConfirm, setIsOpenConfirm] =
    useModal(false);

  function confirmFunction() {
    closeModal();
    setIsOpenConfirm(true);
  }

  function acceptFunction() {
    dispatch(acceptQuotationMessage())
      .then(function () {
        history.push("/client/messages");
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  function declineFunction() {
    closeModalConfirm();
    setIsOpen(true);
  }

  return (
    <Flex style={{ cursor: "pointer" }}>
      <div onClick={() => setIsOpen(true)}>
        <img src={eyeIcon} alt="See detail - Icon" />
      </div>
      <BudgetResume
        id={id}
        isOpen={isOpen}
        closeModal={closeModal}
        isMobile={isMobile}
        confirmFunction={confirmFunction}
      />
      <ConfirmAction
        isOpen={isOpenConfirm}
        closeModal={closeModalConfirm}
        width={isMobile ? 300 : 400}
        height={isMobile ? 200 : 250}
        action={"Be careful"}
        question={
          "If you accept, we will redirect you to the chat with our team, to inform you about payment methods."
        }
        cancelFunction={declineFunction}
        cancelText={"No, decline"}
        confirmFunction={acceptFunction}
        confirmText={"Yes, accept"}
      />
    </Flex>
  );
}
