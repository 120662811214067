import React from "react";
// components
import { Flex } from "@chakra-ui/react";
import CloseButton from "./buttons/CloseButton";
import AcceptButton from "./buttons/AcceptButton";

export default function ClientCase({ data, closeFunction, acceptFunction }) {
  return (
    <Flex
      justifyContent={"flex-end"}
      gap={2.5}
      style={{ marginTop: 10, paddingBottom: 20 }}
    >
      <CloseButton closeFunction={closeFunction} />
      {data.status === "ready" && (
        <AcceptButton acceptFunction={acceptFunction} />
      )}
    </Flex>
  );
}
