import React from "react";
import Layout from "layouts/auth";
// components
import { Flex } from "@chakra-ui/react";
import AuthHeader from "components/authHeader/AuthHeader";
import LoginForm from "./sections/LoginForm/LoginForm";
// utils
import { useResponsive } from "hooks/useResponsive";

export default function Login() {
  const isMobile = useResponsive();

  return (
    <Layout>
      <Flex
        borderRadius={10}
        padding={10}
        backgroundColor={"white"}
        flexDirection={"column"}
      >
        <AuthHeader
          title={"Sign in"}
          caption={"Enter your email and password to sign in!"}
          isMobile={isMobile}
        />
        <LoginForm isMobile={isMobile} />
      </Flex>
    </Layout>
  );
}
