import React from "react";
// components
import { Box, Heading, Text } from "@chakra-ui/react";

export default function AuthHeader({
  color,
  secondColor,
  title,
  caption,
  isMobile,
}) {
  return (
    <Box width={isMobile ? 231 : 400} height={isMobile ? 110 : 90}>
      <Heading color={color} fontSize={isMobile ? 28 : 36} mb="10px">
        {title}
      </Heading>
      {caption && (
        <Text
          color={secondColor}
          fontSize={isMobile ? "14px" : "md"}
          fontWeight="400"
          ms="4px"
          mb={isMobile ? 26 : 36}
        >
          {caption}
        </Text>
      )}
    </Box>
  );
}
