import React from "react";
// components
import { Flex } from "@chakra-ui/react";
import DetailButton from "../buttons/DetailButton";
import CheckButton from "../buttons/CheckButton";

export default function ReadyActions({ id }) {
  return (
    <Flex gap={10} alignItems={"center"}>
      <DetailButton id={id} />
      <CheckButton id={id} type={"ready"} />
    </Flex>
  );
}
