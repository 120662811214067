import React from "react";
// assets
import redirectIcon from "../../../../../../assets/img/icons/redirect.svg";
// components
import { Flex } from "@chakra-ui/react";

export default function UnableRedirectButton() {
  return (
    <Flex
      style={{
        cursor: "pointer",
        filter: "grayscale(100%)",
        opacity: "30%",
        pointerEvents: "none",
      }}
    >
      <img src={redirectIcon} alt="Redirect - Icon" />
    </Flex>
  );
}
