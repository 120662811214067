import React from "react";
// assets
import eyeIcon from "../../../../../../assets/img/icons/eye.svg";
// components
import { Flex } from "@chakra-ui/react";
import QuotationDetail from "components/modal/QuotationDetail/QuotationDetail";
// utils
import { useModal } from "hooks/useModal";
import { useResponsive } from "hooks/useResponsive";

export default function DetailButton({ id }) {
  const [isOpen, openModal, closeModal, setIsOpen] = useModal(false);
  const isMobile = useResponsive();

  return (
    <Flex style={{ cursor: "pointer" }}>
      <div onClick={() => setIsOpen(true)}>
        <img src={eyeIcon} alt="See detail - Icon" />
      </div>
      <QuotationDetail
        id={id}
        userRol={"Cliente"}
        isOpen={isOpen}
        closeModal={closeModal}
        setIsOpen={setIsOpen}
        isMobile={isMobile}
      />
    </Flex>
  );
}
