import axiosInstance from "constants/axiosInstance";
import {
  CREATE_NEW_QUOTATION,
  DELETE_QUOTATION,
  GET_ALL_QUOTATIONS,
  GET_ORDERS_BY_USER_ID,
  GET_ORDERS_BY_PROJECT_ID,
  GET_QUOTATION,
  GET_QUOTATIONS_BY_PROJECT_ID,
  GET_QUOTATIONS_BY_USER_ID,
  UPDATE_QUOTATION_SOLUTION,
  UPDATE_QUOTATION,
  GET_ALL_ORDERS,
  UPDATE_ORDER,
  UPDATE_SERVICE,
} from "../actionTypes";

export function createNewQuotation(data) {
  return async (dispatch) => {
    try {
      const newQuotation = await axiosInstance.post(`/quotations`, data);
      dispatch({
        type: CREATE_NEW_QUOTATION,
      });
      return newQuotation.data;
    } catch (error) {
      return error;
    }
  };
}

export function getAllQuotations() {
  return async (dispatch) => {
    try {
      const quotations = await axiosInstance.get("/quotations/quotationType");
      dispatch({
        type: GET_ALL_QUOTATIONS,
        payload: quotations.data,
      });
      return quotations.data;
    } catch (error) {
      return error;
    }
  };
}

export function getAllOrders() {
  return async (dispatch) => {
    try {
      const orders = await axiosInstance.get("/quotations/orderType");
      dispatch({
        type: GET_ALL_ORDERS,
        payload: orders.data,
      });
      return orders.data;
    } catch (error) {
      return error;
    }
  };
}

export function getUserQuotations(userId) {
  return async (dispatch) => {
    try {
      const quotations = await axiosInstance.get(
        `/quotations/quotationType/user/${userId}`
      );
      dispatch({
        type: GET_QUOTATIONS_BY_USER_ID,
        payload: quotations.data,
      });
      return quotations.data;
    } catch (error) {
      return error;
    }
  };
}

export function getUserQuotationsByProject(projectId) {
  return async (dispatch) => {
    try {
      const quotations = await axiosInstance.get(
        `/quotations/quotationType/project/${projectId}`
      );
      dispatch({
        type: GET_QUOTATIONS_BY_PROJECT_ID,
        payload: quotations.data,
      });
      return quotations.data;
    } catch (error) {
      return error;
    }
  };
}

export function getUserOrders(userId) {
  return async (dispatch) => {
    try {
      const orders = await axiosInstance.get(
        `/quotations/orderType/user/${userId}`
      );
      dispatch({
        type: GET_ORDERS_BY_USER_ID,
        payload: orders.data,
      });
      return orders.data;
    } catch (error) {
      return error;
    }
  };
}

export function getUserOrdersByProject(projectId) {
  return async (dispatch) => {
    try {
      const orders = await axiosInstance.get(
        `/quotations/orderType/project/${projectId}`
      );
      dispatch({
        type: GET_ORDERS_BY_PROJECT_ID,
        payload: orders.data,
      });
      return orders.data;
    } catch (error) {
      return error;
    }
  };
}

export function getQuotation(quotationId) {
  return async (dispatch) => {
    try {
      const quotation = await axiosInstance.get(`quotations/${quotationId}`);
      dispatch({
        type: GET_QUOTATION,
      });
      return quotation.data;
    } catch (error) {
      return error;
    }
  };
}

export function deleteQuotation(quotationId) {
  return async (dispatch) => {
    try {
      const deleted = await axiosInstance.delete(`quotations/${quotationId}`);
      dispatch({
        type: DELETE_QUOTATION,
      });
      return deleted;
    } catch (error) {
      return error;
    }
  };
}

export function updateQuotation(quotationId) {
  return async (dispatch) => {
    try {
      const updated = await axiosInstance.patch(`quotations/${quotationId}`, {
        status: "in progress",
        type: "order",
      });
      dispatch({
        type: UPDATE_QUOTATION,
      });
      return updated;
    } catch (error) {
      return error;
    }
  };
}

export function updateQuotationSolutionPrice(data, quotationId) {
  return async (dispatch) => {
    try {
      const updated = await axiosInstance.patch(
        `service/quotationSolution/${quotationId}`,
        data
      );
      dispatch({
        type: UPDATE_SERVICE,
      });
      return updated.data;
    } catch (error) {
      return error;
    }
  };
}

export function updateOrder(quotationId) {
  return async (dispatch) => {
    try {
      const updated = await axiosInstance.patch(`quotations/${quotationId}`, {
        status: "ready",
      });
      dispatch({
        type: UPDATE_ORDER,
      });
      return updated;
    } catch (error) {
      return error;
    }
  };
}
