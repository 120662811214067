import React from "react";
import style from "../styles/header.module.css";
// components
import { Flex } from "@chakra-ui/react";

export default function Header({ client }) {
  return (
    <Flex className={style.container}>
      <p>{client.name}</p>
    </Flex>
  );
}
