import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// components
import { Flex } from "@chakra-ui/react";
import ForgotPasswordModal from "./ForgotPasswordModal";
import Message from "components/modal/Message/Message";
// utils
import { useModal } from "hooks/useModal";

export default function ForgotPassword({ isMobile }) {
  const [isOpen, openModal, closeModal, setIsOpen] = useModal(false);
  const [isOpenSuccess, openModalSuccess, closeModalSuccess, setIsOpenSuccess] =
    useModal(false);
  const [isOpenError, openModalError, closeModalError, setIsOpenError] =
    useModal(false);

  const handleSuccess = () => {
    setIsOpen(false);
    setIsOpenSuccess(true);
  };

  const handleError = () => {
    setIsOpen(false);
    setIsOpenError(true);
  };

  return (
    <>
      <Flex fontSize="sm" onClick={() => setIsOpen(true)}>
        <p style={{ color: "#0AAA9D", fontWeight: 500, cursor: "pointer" }}>
          Forgot password?
        </p>
      </Flex>
      <ForgotPasswordModal
        isOpen={isOpen}
        closeModal={closeModal}
        isMobile={isMobile}
        onSuccess={handleSuccess}
        onError={handleError}
      />
      <Message
        isOpen={isOpenSuccess}
        closeModal={closeModalSuccess}
        width={isMobile ? 320 : 450}
        height={isMobile ? 200 : 220}
        title={"Success"}
        caption={"We send an email to your mailbox to restore your password."}
        buttonFunction={closeModalSuccess}
        buttonText={"Close"}
      />
      <Message
        isOpen={isOpenError}
        closeModal={closeModalError}
        width={isMobile ? 320 : 450}
        height={isMobile ? 200 : 220}
        title={"Error"}
        caption={"An error ocurred sending the email to restore your password"}
        buttonFunction={closeModalSuccess}
        buttonText={"Close"}
      />
    </>
  );
}
