import axiosInstance from "constants/axiosInstance";
import {
  GET_MESSAGES_BY_USER_ID,
  GET_ADMIN_UNREAD_MESSAGES,
  GET_USER_UNREAD_MESSAGES,
  READ_USER_MESSAGES,
  READ_ADMIN_MESSAGES,
  CREATE_MESSAGE,
} from "../actionTypes";

export function createMessage(data) {
  return async (dispatch) => {
    try {
      const newMessage = await axiosInstance.post(`/mensajes`, data);
      dispatch({
        type: CREATE_MESSAGE,
      });
      return newMessage;
    } catch (error) {
      return error;
    }
  };
}

export function getUserMessages(userId) {
  return async (dispatch) => {
    try {
      const messages = await axiosInstance.get(`/mensajes/user/${userId}`);
      dispatch({
        type: GET_MESSAGES_BY_USER_ID,
        payload: messages.data,
      });
      return messages.data;
    } catch (error) {
      return error;
    }
  };
}

export function getNewUserMessages(clientId) {
  return async (dispatch) => {
    try {
      const unread = await axiosInstance.get(`/mensajes/new/user/${clientId}`);
      dispatch({
        type: GET_USER_UNREAD_MESSAGES,
      });
      return unread.data;
    } catch (error) {
      return error;
    }
  };
}

export function getNewAdminMessages(clientId) {
  return async (dispatch) => {
    try {
      const unread = await axiosInstance.get(`/mensajes/new/admin/${clientId}`);
      dispatch({
        type: GET_ADMIN_UNREAD_MESSAGES,
      });
      return unread.data;
    } catch (error) {
      return error;
    }
  };
}

export function readClientMessages(clientId) {
  return async (dispatch) => {
    try {
      const setRead = await axiosInstance.patch(
        `/mensajes/update/user/${clientId}`
      );
      dispatch({
        type: READ_USER_MESSAGES,
      });
      return setRead.data;
    } catch (error) {
      return error;
    }
  };
}

export function readAdminMessages(clientId) {
  return async (dispatch) => {
    try {
      const setRead = await axiosInstance.patch(
        `/mensajes/update/admin/${clientId}`
      );
      dispatch({
        type: READ_ADMIN_MESSAGES,
      });
      return setRead.data;
    } catch (error) {
      return error;
    }
  };
}
