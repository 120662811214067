import React from "react";
// components
import { Flex } from "@chakra-ui/react";
import FormCheckbox from "components/inputs/FormCheckbox/FormCheckbox";
import CustomLabel from "components/customLabel/CustomLabel";

export default function KeepMeLogged() {
  // in future, configure this component to dispatch the action with some particular thing that indicate one login token without expiration time.
  return (
    <Flex justifyContent={"center"} alignItems={"center"}>
      <FormCheckbox />
      <div style={{ height: 19 }}>
        <CustomLabel color={"#525c60"} name={"Keep me logged in"} />
      </div>
    </Flex>
  );
}
