import React from "react";
import style from "../../../styles/progress.module.css";
// components
import { Flex } from "@chakra-ui/react";
import ProgressBar from "./ProgressBar";

export default function Progress({ currentStep }) {
  return (
    <Flex className={style.progressSection}>
      <Flex className={style.description}>
        <h2>New quotation</h2>
        <Flex className={style.text}>
          <p>Welcome to Taxon! Let’s work together.</p>
          <p>
            Please provide the requested information to generate a budget
            tailored to the needs of your project.
          </p>
          <p>
            You can choose from the listed services or make a customized
            request.
          </p>
        </Flex>
      </Flex>
      <ProgressBar currentStep={currentStep} />
    </Flex>
  );
}
