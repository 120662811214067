// Auth action types
export const CLEAR_USER_STATE = "CLEAR_USER_STATE";
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_WITH_GOOGLE = "USER_LOGIN_WITH_GOOGLE";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_REGISTER = "USER_REGISTER";
export const USER_VERIFY_WITH_GOOGLE = "USER_VERIFY_WITH_GOOGLE";
export const USER_VERIFY_JWT = "USER_VERIFY_JWT";

// Company action types
export const CREATE_NEW_COMPANY = "CREATE_NEW_COMPANY";
export const GET_COMPANIES_BY_USER_ID = "GET_COMPANIES_BY_USER_ID";
export const GET_COMPANY = "GET_COMPANY";

// Messages action types
export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const GET_MESSAGES_BY_USER_ID = "GET_MESSAGES_BY_USER_ID";
export const GET_USER_UNREAD_MESSAGES = "GET_USER_UNREAD_MESSAGES";
export const GET_ADMIN_UNREAD_MESSAGES = "GET_ADMIN_UNREAD_MESSAGES";
export const READ_USER_MESSAGES = "READ_USER_MESSAGES";
export const READ_ADMIN_MESSAGES = "READ_ADMIN_MESSAGES";

// Project action types
export const CREATE_NEW_PROJECT = "CREATE_NEW_PROJECT";
export const GET_ALL_PROJECTS = "GET_ALL_PROJECTS";
export const GET_PROJECTS_BY_USER_ID = "GET_PROJECTS_BY_USER_ID";
export const GET_PROJECT = "GET_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";

// Quotation action types
export const CREATE_NEW_QUOTATION = "CREATE_NEW_QUOTATION";
export const GET_ALL_QUOTATIONS = "GET_ALL_QUOTATIONS";
export const GET_QUOTATIONS_BY_USER_ID = "GET_QUOTATIONS_BY_USER_ID";
export const GET_QUOTATIONS_BY_PROJECT_ID = "GET_QUOTATIONS_BY_PROJECT_ID";
export const GET_ALL_ORDERS = "GET_ALL_ORDERS";
export const GET_ORDERS_BY_USER_ID = "GET_ORDERS_BY_USER_ID";
export const GET_ORDERS_BY_PROJECT_ID = "GET_ORDERS_BY_PROJECT_ID";
export const GET_QUOTATION = "GET_QUOTATION";
export const DELETE_QUOTATION = "DELETE_QUOTATION";
export const UPDATE_QUOTATION = "UPDATE_QUOTATION";
export const UPDATE_QUOTATION_SOLUTION = "UPDATE_QUOTATION_SOLUTION";
export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const UPDATE_ORDER = "UPDATE_ORDER";

// Results action types
export const CREATE_NEW_RESULT = "CREATE_NEW_RESULT";
export const GET_RESULTS_BY_PROJECT_ID = "GET_RESULTS_BY_PROJECT_ID";
export const DELETE_RESULT = "DELETE_RESULT";

// Samples action types
export const CREATE_NEW_SAMPLE = "CREATE_NEW_SAMPLE";
export const GET_SAMPLES_BY_PROJECT_ID = "GET_SAMPLES_BY_PROJECT_ID";
export const DELETE_SAMPLE = "DELETE_SAMPLE";

// User action types
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_USER_BY_USER_ID = "GET_USER_BY_USER_ID";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";

// Provisory action
export const ACCEPT_QUOTATION_MESSAGE = "ACCEPT_QUOTATION_MESSAGE";
export const CLEAN_MESSAGE = "CLEAN_MESSAGE";
