import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// actions
import { register } from "redux/actions/user";
// components
import { Button, Flex, Link } from "@chakra-ui/react";
import CustomLabel from "components/customLabel/CustomLabel";
import FormInput from "components/inputs/FormInput/FormInput";
import InputPassword from "components/inputs/InputPassword/InputPassword";
import GoogleRegister from "../../components/GoogleRegister/GoogleRegister";
import Loader from "components/loader/Loader";
import ErrorText from "components/errorText/ErrorText";
// utils
import { useForm } from "hooks/useForm";
import FormCheckbox from "components/inputs/FormCheckbox/FormCheckbox";

export default function RegisterForm({ isMobile }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [googleData, setGoogleData] = useState(null);

  const initialForm = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const validateForm = (form) => {
    let errors = {};
    let regexEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (form.name === "") {
      errors.name = "Empty field";
    }

    if (form.email === "") {
      errors.email = "Empty field";
    } else if (!regexEmail.test(form.email)) {
      errors.email = "The email format isn't correct";
    }

    if (form.password === "") {
      errors.password = "Empty field";
    } else if (form.password.length < 8) {
      errors.password = "Password must be at least 8 characters";
    }

    if (form.confirmPassword === "") {
      errors.confirmPassword = "Empty field";
    } else if (form.confirmPassword !== form.password) {
      errors.confirmPassword = "Passwords don't match";
    }

    return errors;
  };

  const { form, errors, response, send, handleChange, handleSubmit } = useForm(
    initialForm,
    validateForm
  );

  useEffect(() => {
    if (response) {
      dispatch(register(form))
        .then(function (res) {
          setIsLoading(true);
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }, [response]);

  useEffect(() => {
    if (googleData) {
      dispatch(register(googleData))
        .then(function (res) {
          setIsLoading(true);
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }, [googleData]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <form>
        <section>
          <CustomLabel
            color={"#525c60"}
            highlightColor={"#0aaa9d"}
            name={"Name"}
          />
          <FormInput
            inputId={"name"}
            inputName={"name"}
            inputValue={form.name}
            inputPlaceholder={"Enter your name"}
            handleChange={handleChange}
          />
          {send && errors.name && <ErrorText err={errors.name} />}
        </section>
        <section>
          <CustomLabel
            color={"#525c60"}
            highlightColor={"#0aaa9d"}
            name={"Email"}
          />
          <FormInput
            inputId={"email"}
            inputName={"email"}
            inputValue={form.email}
            inputPlaceholder={"Enter your email"}
            handleChange={handleChange}
          />
          {send && errors.email && <ErrorText err={errors.email} />}
        </section>
        <section>
          <CustomLabel
            color={"#525c60"}
            highlightColor={"#0aaa9d"}
            name={"Password"}
          />
          <InputPassword
            iconColor={"#525c6060"}
            inputId={"password"}
            inputName={"password"}
            inputValue={form.password}
            inputPlaceholder={"Min. 8 characters"}
            handleChange={handleChange}
          />
          {send && errors.password && <ErrorText err={errors.password} />}
        </section>
        <section>
          <CustomLabel
            color={"#525c60"}
            highlightColor={"#0aaa9d"}
            name={"Confirm password"}
          />
          <InputPassword
            iconColor={"#525c6060"}
            inputId={"confirmPassword"}
            inputName={"confirmPassword"}
            inputValue={form.confirmPassword}
            inputPlaceholder={"Min. 8 characters"}
            handleChange={handleChange}
          />
          {send && errors.confirmPassword && (
            <ErrorText err={errors.confirmPassword} />
          )}
        </section>
        <Flex justifyContent={"flex-start"} alignItems={"center"}>
          <FormCheckbox />
          <div style={{ height: 19 }}>
            <CustomLabel
              color={"#525c60"}
              name={`I'm accept all terms & conditions`}
            />
          </div>
        </Flex>
        <Button
          variant="brand"
          onClick={handleSubmit}
          width={"100%"}
          mt={5}
          fontWeight="500"
          fontSize={"sm"}
        >
          Register now
        </Button>
        <GoogleRegister setGoogleData={setGoogleData} isMobile={isMobile} />
        <Flex fontSize="sm" justifyContent={"flex-end"}>
          <p>
            Already have an account?{" "}
            <Link
              href="#/auth/login"
              style={{ color: "#0AAA9D", fontWeight: 500, cursor: "pointer" }}
            >
              Login now.
            </Link>
          </p>
        </Flex>
      </form>
    </>
  );
}
