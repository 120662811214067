import React, { useEffect, useRef } from "react";
import style from "../../styles/messages.module.css";
// components
import { Flex } from "@chakra-ui/react";
import SendBox from "../../components/SendBox";

const Message = ({ userRol, content }) => {
  return (
    <Flex
      className={
        userRol === "Administrador" ? style.adminMessage : style.clientMessage
      }
    >
      <Flex className={style.message}>
        <p>{content}</p>
      </Flex>
    </Flex>
  );
};

export default function Messages({ user, data }) {
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [data]);

  return (
    <Flex className={style.container}>
      <div className={style.scroll} ref={messagesEndRef}>
        <Flex flexDirection={"column"} className={style.messagesContainer}>
          {data &&
            data.map((mensaje) => (
              <Message
                key={mensaje.id}
                userRol={
                  mensaje.emisor.id === user.id ? "Cliente" : "Administrador"
                }
                content={mensaje.contenido}
              />
            ))}
        </Flex>
      </div>
      <SendBox userId={user.id} />
    </Flex>
  );
}
