import React from "react";
import style from "../../styles/header.module.css";
// assets
import backArrow from "../../../../../assets/img/icons/back.svg";
// components
import { Flex, Heading, Link } from "@chakra-ui/react";

export default function Header() {
  return (
    <Heading className={style.container}>
      <Link href="#/admin/projects" className={style.link}>
        <Flex gap={15}>
          <img src={backArrow} alt="Back arrow" />
          <p style={{ color: "#0AAA9D" }}>Back</p>
        </Flex>
      </Link>
    </Heading>
  );
}
