export const columnsDataDevelopment = [
  {
    Header: "Created",
    accessor: "createTime",
  },
  {
    Header: "Project name",
    accessor: "title",
  },
  {
    Header: "Company",
    accessor: "company.name",
  },
  {
    Header: "ID",
    accessor: "id",
    disableSortBy: true,
  },
];
