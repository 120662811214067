import React from "react";
// components
import { Flex } from "@chakra-ui/react";
import UploadButton from "../buttons/UploadButton";
import UnableRedirectButton from "../buttons/UnableRedirectButton";

export default function InProgressActions({ id }) {
  return (
    <Flex gap={10} alignItems={"center"}>
      <UploadButton id={id} />
      <UnableRedirectButton />
    </Flex>
  );
}
