import React from "react";
// components
import { Box, Flex } from "@chakra-ui/react";
import BoxError from "components/boxError/BoxError";
import ProjectsTable from "./ProjectsTable/ProjectsTable";
// variables
import { columnsDataDevelopment } from "../variables/columnsData";

export default function TableContainer({ projects, isMobile }) {
  return (
    <Flex flexDirection={"column"}>
      {projects && projects[0] !== undefined ? (
        <Box bg="white" w="100%" p={4} color="black" borderRadius={20}>
          <ProjectsTable
            columnsData={columnsDataDevelopment}
            tableData={projects}
            isMobile={isMobile}
          />
        </Box>
      ) : (
        <BoxError message={"There aren't projects yet."} />
      )}
    </Flex>
  );
}
