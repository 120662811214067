import React from "react";
// components
import { Button } from "@chakra-ui/react";

export default function AcceptButton({ acceptFunction }) {
  return (
    <Button variant="brand" onClick={acceptFunction}>
      Accept
    </Button>
  );
}
