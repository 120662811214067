import React from "react";
import { useDispatch } from "react-redux";
import style from "../../../styles/quotationTable.module.css";
// actions
import { updateQuotation } from "redux/actions/quotation";
import { getAllQuotations } from "redux/actions/quotation";
import { getAllOrders } from "redux/actions/quotation";
// assets
import checkIcon from "../../../../../../assets/img/icons/check-aqua.svg";
// components
import { Flex } from "@chakra-ui/react";
import ConfirmAction from "components/modal/ConfirmAction/ConfirmAction";
// utils
import { useResponsive } from "hooks/useResponsive";
import { useModal } from "hooks/useModal";

export default function CheckButton({ id }) {
  const dispatch = useDispatch();
  const isMobile = useResponsive();
  const [isOpen, openModal, closeModal, setIsOpen] = useModal(false);

  function makeOrder() {
    dispatch(updateQuotation(id))
      .then(function () {
        dispatch(getAllQuotations())
          .then(function () {
            dispatch(getAllOrders());
            setIsOpen(false);
          })
          .catch(function (err) {
            console.log(err);
          });
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  return (
    <>
      <Flex className={style.check} onClick={() => setIsOpen(true)}>
        <img src={checkIcon} alt="Check - Icon" />
      </Flex>
      <ConfirmAction
        isOpen={isOpen}
        closeModal={closeModal}
        width={isMobile ? 330 : 400}
        height={230}
        action={"Be careful"}
        question={"Accepting, you confirm that user make the payment."}
        cancelFunction={closeModal}
        cancelText={"No, decline"}
        confirmFunction={makeOrder}
        confirmText={"Yes, accept"}
      />
    </>
  );
}
