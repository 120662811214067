import React, { useState } from "react";
import style from "./styles/inputAndSelect.module.css";
// components
import { Box, Input, Text } from "@chakra-ui/react";

export default function FormInputandSelect({
  inputName,
  inputValue,
  inputPlaceholder,
  handleChange,
  isRequired,
  existingData,
  matchingAttribute,
  setSelectedData,
}) {
  const [selectVisible, setSelectVisible] = useState(false);

  const handleInputChange = (e) => {
    const { value } = e.target;
    handleChange(e); // Warn to father's component the event change
    setSelectVisible(true);
    setSelectedData(false);
  };

  const handleSelect = (elementValue) => {
    handleChange({ target: { name: inputName, value: elementValue } });
    setSelectVisible(false); // Hide the select after choose an option
    setSelectedData(true);
  };

  const findPartialMatches = (input) => {
    return existingData.filter((element) =>
      element[matchingAttribute].toLowerCase().includes(input.toLowerCase())
    );
  };

  const partialMatches = inputValue ? findPartialMatches(inputValue) : [];

  return (
    <div className={style.container}>
      <Input
        type="text"
        isRequired={isRequired ? isRequired : false}
        fontSize="sm"
        ms={{ base: "0px", md: "0px" }}
        size="lg"
        borderRadius={5}
        variant="auth"
        name={inputName}
        value={inputValue}
        placeholder={inputPlaceholder}
        onChange={handleInputChange}
        style={{backgroundColor: '#fff'}}
      />

      {partialMatches.length > 0 && selectVisible && (
        <Box
          position="absolute"
          top="100%"
          left="0"
          right="0"
          bg="white"
          border="1px solid #d9d9d960"
          borderRadius="5px"
          mt="2px"
          zIndex="1"
        >
          {partialMatches.map((element) => (
            <Text
              key={element.id}
              px="3"
              py="2"
              cursor="pointer"
              _hover={{ bg: "#f4f4f4" }}
              onClick={() => handleSelect(element[matchingAttribute])}
            >
              {element[matchingAttribute]}
            </Text>
          ))}
        </Box>
      )}
    </div>
  );
}
