import React from "react";
import style from "../../../styles/profile.module.css";
// components
import { Box, Flex } from "@chakra-ui/react";
import CustomLabel from "components/customLabel/CustomLabel";
import FormInput from "components/inputs/FormInput/FormInput";

export default function Info({ data }) {
  return (
    <Box bg="transparent" w="100%" p={4} color="black" borderRadius={20}>
      <form>
        <section className={style.section}>
          <CustomLabel
            color={"#525c60"}
            highlightColor={"#0aaa9d"}
            name={`Name`}
          />
          <div className={style.inputContainer}>
            <FormInput
              inputId={"name"}
              inputName={"name"}
              inputValue={data.name}
              inputPlaceholder={data.name}
              isDisabled={true}
              disabledStyle={true}
            />
          </div>
        </section>
        <Flex justifyContent={"space-between"} gap={2.5}>
          <section className={style.section}>
            <CustomLabel
              color={"#525c60"}
              highlightColor={"#0aaa9d"}
              name={`Email`}
            />
            <div className={style.inputContainer}>
              <FormInput
                inputId={"email"}
                inputName={"email"}
                inputValue={data.email}
                inputPlaceholder={data.email}
                isDisabled={true}
                disabledStyle={true}
              />
            </div>
          </section>
          <section className={style.section}>
            <CustomLabel
              color={"#525c60"}
              highlightColor={"#0aaa9d"}
              name={`Phone number`}
            />
            <div className={style.inputContainer}>
              <FormInput
                inputId={"phone"}
                inputName={"phone"}
                inputValue={data.phone}
                inputPlaceholder={data.phone}
                isDisabled={true}
                disabledStyle={true}
              />
            </div>
          </section>
        </Flex>
      </form>
    </Box>
  );
}
