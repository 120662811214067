import React from "react";
import style from "../../styles/companyInformation.module.css";
// components
import { Box, Flex } from "@chakra-ui/react";
import Card from "components/card/Card";
import BoxError from "components/boxError/BoxError";
import CompaniesTable from "../../components/CompaniesTable/CompaniesTable";
// variables
import columnsDataCompanies from "../../variables/columnDataCompanies";

export default function CompanyInformation({ data }) {
  return (
    <Card>
      <Flex flexDirection={"column"}>
        <Box bg="white" w="100%" p={4} color="black" borderRadius={20}>
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <h2 className={style.sectionTitle}>Companies</h2>
          </Flex>
        </Box>
        {data && data[0] !== undefined ? (
          <CompaniesTable columnsData={columnsDataCompanies} tableData={data} />
        ) : (
          <BoxError message={"There aren't companies yet."} />
        )}
      </Flex>
    </Card>
  );
}
