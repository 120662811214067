import React from "react";
import style from "../../styles/userInformation.module.css";
// components
import { Box, Flex } from "@chakra-ui/react";
import Card from "components/card/Card";

export default function UserInformation({ data }) {
  return (
    <Flex flexDirection={"column"} gap={2.5} style={{ marginTop: 30 }}>
      <h2 className={style.title}>{data.name}</h2>
      <Card>
        <Flex flexDirection={"column"} gap={5}>
          <Box
            className={style.contentContainer}
            bg="white"
            w="100%"
            p={4}
            color="black"
            borderRadius={20}
          >
            <Flex gap={"50%"}>
              <div className={style.section}>
                <h4>E - mail</h4>
                <p>{data.email}</p>
              </div>
              <div className={style.section}>
                <h4>Phone number</h4>
                <p>{data.phone}</p>
              </div>
            </Flex>
          </Box>
        </Flex>
      </Card>
    </Flex>
  );
}
