import React from "react";
import style from "./styles/container.module.css";
import { Flex } from "@chakra-ui/react";

export default function ViewContainer({ children }) {
  return (
    <Flex
      pt={{ base: "130px", md: "80px", xl: "80px" }}
      className={style.container}
    >
      {children}
    </Flex>
  );
}
