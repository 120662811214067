export const columnsDataDevelopment = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "E-mail",
    accessor: "email",
  },
  {
    Header: "Phone number",
    accessor: "phone",
  },
  {
    Header: "ID",
    accessor: "id",
    disableSortBy: true,
  },
];
