import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store, persistor } from "redux/store";
import { PersistGate } from "redux-persist/integration/react";
import "assets/css/App.css";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import { GoogleOAuthProvider } from "@react-oauth/google";
import UserRoutes from "userRoutes";

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <ThemeEditorProvider>
        <Provider store={store}>
          <GoogleOAuthProvider clientId="916525164343-jol4kqonhhb8j1gmopiedms4v5c7ms59.apps.googleusercontent.com">
            <PersistGate loading={null} persistor={persistor}>
              <UserRoutes />
            </PersistGate>
          </GoogleOAuthProvider>
        </Provider>
      </ThemeEditorProvider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
);
