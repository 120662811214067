import React from "react";
import style from "../../styles/quotations.module.css";
// components
import { Box, Flex } from "@chakra-ui/react";
import OrderTable from "../../components/OrderTable/OrderTable";
import BoxError from "components/boxError/BoxError";
// variables
import columnsDataDevelopment from "../../variables/columsDataOrder";

export default function Orders({ data }) {
  //console.log("toda la info que trae orders", data);
  return (
    <Flex className={style.container}>
      <h2>Orders</h2>
      {data && data[0] !== undefined ? (
        <Box bg="white" w="100%" p={4} color="black" borderRadius={20}>
          <OrderTable columnsData={columnsDataDevelopment} tableData={data} />
        </Box>
      ) : (
        <BoxError message={"There aren't any orders"} />
      )}
    </Flex>
  );
}
