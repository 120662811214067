import React from "react";
// assets
import trashIcon from "../../../../../../assets/img/icons/trash.svg";
// components
import { Flex } from "@chakra-ui/react";

export default function UnableDeleteButton() {
  return (
    <Flex
      style={{
        cursor: "pointer",
        pointerEvents: "none",
        filter: "grayscale(100%)",
        opacity: "30%",
      }}
    >
      <img src={trashIcon} alt="Delete - Icon" />
    </Flex>
  );
}