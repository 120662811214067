import React, { useState } from "react";
import style from "../../styles/profile.module.css";
// assets
import editIcon from "../../../../../assets/img/icons/edit.svg";
// components
import { Box, Flex } from "@chakra-ui/react";
import EditableInfo from "./sections/EditableInfo";
import Info from "./sections/Info";

export default function PersonalInformation({ data }) {
  const [editable, setEditable] = useState(false);

  return (
    <Box bg="transparent" w="100%" p={4} color="black" borderRadius={20}>
      <Flex flexDirection={"column"}>
        <Box bg="transparent" w="100%" p={4} color="black" borderRadius={20}>
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <h2 className={style.sectionTitle}>Your information</h2>
            <Flex
              style={{ cursor: "pointer" }}
              onClick={() => setEditable(!editable)}
            >
              <img src={editIcon} alt="Edit - Your personal information" />
            </Flex>
          </Flex>
        </Box>
        {editable ? (
          <EditableInfo
            data={data}
            cancelFunction={() => setEditable(!editable)}
          />
        ) : (
          <Info data={data} />
        )}
      </Flex>
    </Box>
  );
}
