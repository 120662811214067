import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import style from "../../styles/conversations.module.css";
// actions
import { getNewUserMessages } from "redux/actions/messages";
// assets
import dotIcon from "../../../../../assets/img/icons/dot.svg";
// components
import { Flex } from "@chakra-ui/react";
import { readAdminMessages } from "redux/actions/messages";

export default function Conversations({ user }) {
  const dispatch = useDispatch();
  const [isNewMsg, setIsNewMsg] = useState(0);

  useEffect(() => {
    dispatch(getNewUserMessages(user.id))
      .then(function (res) {
        setIsNewMsg(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [user]);

  const onClickFunction = (userId) => {
    dispatch(readAdminMessages(user.id))
      .then(function () {
        dispatch(getNewUserMessages(user.id)).then(function (res) {
          setIsNewMsg(res);
        });
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  return (
    <Flex className={style.container}>
      <Flex
        className={`${style.conversation} ${
          isNewMsg !== 0 && style.newMessage
        }`}
        onClick={() => onClickFunction(user.id)}
      >
        <div className={style.avatar}>
          <p>A</p>
        </div>
        <p>Administrador</p>
        {isNewMsg !== 0 && (
          <img
            src={dotIcon}
            alt="New Message"
            className={style.newMessageIcon}
          />
        )}
      </Flex>
    </Flex>
  );
}
