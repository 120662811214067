import React from "react";
import { useDispatch, useSelector } from "react-redux";
// actions
import { deleteQuotation } from "redux/actions/quotation";
import { getUserQuotations } from "redux/actions/quotation";
// assets
import trashIcon from "../../../../../../assets/img/icons/trash.svg";
// components
import { Flex } from "@chakra-ui/react";
import ConfirmAction from "components/modal/ConfirmAction/ConfirmAction";
// utils
import { useModal } from "hooks/useModal";

export default function DeleteButton({ id, isMobile }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);

  const [isOpen, openModal, closeModal, setIsOpen] = useModal(false);

  function deleteItem(quotationId) {
    dispatch(deleteQuotation(quotationId))
      .then(function () {
        dispatch(getUserQuotations(user.id));
        setIsOpen(false);
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  return (
    <>
      <Flex style={{ cursor: "pointer" }} onClick={() => setIsOpen(true)}>
        <img src={trashIcon} alt="Delete - Icon" />
      </Flex>
      <ConfirmAction
        isOpen={isOpen}
        closeModal={closeModal}
        width={isMobile ? 330 : 400}
        height={200}
        action={"Delete result"}
        question={"Are you sure you want to delete this quotation?"}
        cancelFunction={closeModal}
        cancelText={"No, keep it"}
        confirmFunction={() => deleteItem(id)}
        confirmText={"Yes, delete"}
      />
    </>
  );
}
