import React from "react";
import style from "./styles/auth.module.css";
import { Flex } from "@chakra-ui/react";
// Components
import { Logo } from "components/logo/Logo";
import { FooterLogin } from "components/footer/FooterLogin";
import { useResponsive } from "hooks/useResponsive";

function AuthLayout(props) {
  const { children } = props;
  const isMobile = useResponsive();

  return (
    <>
      <Logo isMobile={isMobile} />
      <Flex className={style.container} h="max-content">
        <Flex
          h={{
            sm: "140vh",
            md: "140vh",
            lg: "140vh",
            xl: "120vh",
          }}
          className={style.subContainer}
        >
          {children}
        </Flex>
        <FooterLogin isMobile={isMobile} />
      </Flex>
    </>
  );
}

export default AuthLayout;
