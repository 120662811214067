import React from "react";
// components
import { Flex } from "@chakra-ui/react";
import ChatButton from "../buttons/ChatButton";
import UnableDeleteButton from "../buttons/UnableDeleteButton";
import BudgetDetailButton from "../buttons/BudgetDetailButton";
// utils
import { useModal } from "hooks/useModal";

export default function ReadyActions({ id, isMobile }) {
  const [isOpen, openModal, closeModal, setIsOpen] = useModal(false);

  return (
    <Flex gap={10} alignItems={"center"}>
      <BudgetDetailButton
        id={id}
        isOpen={isOpen}
        closeModal={closeModal}
        setIsOpen={setIsOpen}
        isMobile={isMobile}
      />
      <ChatButton />
      <UnableDeleteButton />
    </Flex>
  );
}
