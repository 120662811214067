import React from "react";
import style from "./styles/formCheckbox.module.css";

export default function FormCustomCheckbox({
  inputName,
  inputValue,
  content,
  onChangeFunction,
  disabled = false,
  defaultChecked = false,
}) {
  return (
    <div className={style.contCheckbox}>
      <input
        type="checkbox"
        name={inputName}
        value={inputValue}
        onChange={(e) => onChangeFunction(e)}
        disabled={disabled}
        defaultChecked={defaultChecked}
      />
      <label className={style.customCheckbox}></label>
      <span className={style.text}>{content}</span>
    </div>
  );
}
