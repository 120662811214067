import axiosInstance from "constants/axiosInstance";
import {
  CREATE_NEW_RESULT,
  DELETE_RESULT,
  GET_RESULTS_BY_PROJECT_ID,
} from "../actionTypes";

export function createNewResult(data) {
  return async (dispatch) => {
    try {
      const newResult = await axiosInstance.post(`results`, data);
      dispatch({
        type: CREATE_NEW_RESULT,
      });
      return newResult;
    } catch (error) {
      return error;
    }
  };
}

export function getResultsByProject(projectId) {
  return async (dispatch) => {
    try {
      const results = await axiosInstance.get(`results/project/${projectId}`);
      dispatch({
        type: GET_RESULTS_BY_PROJECT_ID,
        payload: results.data,
      });
      return results.data;
    } catch (error) {
      return error;
    }
  };
}

export function deleteResult(resultId) {
  return async (dispatch) => {
    try {
      const deleted = await axiosInstance.delete(`results/${resultId}`);
      dispatch({
        type: DELETE_RESULT,
      });
      return deleted;
    } catch (error) {
      return error;
    }
  };
}
