import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// actions
import { getQuotation } from "redux/actions/quotation";
import { acceptQuotationMessage } from "redux/actions/user";
import { updateQuotationSolutionPrice } from "redux/actions/quotation";
import { getAllQuotations } from "redux/actions/quotation";
// components
import { Flex } from "@chakra-ui/react";
import Modal from "components/modal/Modal";
import PersonalDetails from "./sections/PersonalDetails";
import ProjectDetails from "./sections/ProjectDetails";
import Services from "./sections/Services";
import Samples from "./sections/Samples";
import ClientCase from "./actions/ClientCase";
import AdminCase from "./actions/AdminCase";
import CreateBudget from "views/admin/Services/components/CreateBudget/CreateBudget";
import ConfirmAction from "../ConfirmAction/ConfirmAction";
// utils
import { useModal } from "hooks/useModal";

export default function QuotationDetail({
  id,
  onlySee,
  userRol,
  isOpen,
  closeModal,
  setIsOpen,
  isMobile,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [quotation, setQuotation] = useState(null);
  const [budgetData, setBudgetData] = useState(null);

  // Admin actions manage
  const [isOpenAdmin, openModalAdmin, closeModalAdmin, setIsOpenAdmin] =
    useModal(false);
  const [isOpenConfirm, openModalConfirm, closeModalConfirm, setIsOpenConfirm] =
    useModal(false);
  function openCreateBudget() {
    closeModal();
    setIsOpenAdmin(true);
  }

  // Admin functions
  function backFunction() {
    closeModalAdmin();
    setIsOpen(true);
  }
  function nextFunction(formData) {
    closeModalAdmin();
    setBudgetData(formData);
    setIsOpenConfirm(true);
  }
  function cancelAdminFunction() {
    closeModalConfirm();
    setIsOpenAdmin(true);
  }
  function confirmAdminFunction() {
    dispatch(
      updateQuotationSolutionPrice(
        budgetData,
        quotation.quotationsolutions[0].id
      )
    )
      .then(function () {
        closeModalConfirm();
        dispatch(getAllQuotations());
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  //Client actions manage
  const [isOpenClient, openModalClient, closeModalClient, setIsOpenClient] =
    useModal(false);
  // Client functions
  function clientNextFunction() {
    closeModal();
    setIsOpenClient(true);
  }
  function cancelClientFunction() {
    setIsOpenClient(false);
    setIsOpen(true);
  }
  function confirmClientFunction() {
    dispatch(acceptQuotationMessage(quotation))
      .then(function () {
        history.push("/client/messages");
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  useEffect(() => {
    dispatch(getQuotation(id))
      .then(function (res) {
        setQuotation(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [id]);

  if (!quotation) {
    return null;
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        width={isMobile ? 330 : 700}
        height={"80vh"}
      >
        <Flex flexDirection={"column"} width={"90%"}>
          <Flex style={{ textTransform: "uppercase" }}>
            <h2 style={{ color: "#0AAA9D", fontSize: 18 }}>
              Quotation Summary - #{quotation.id}
            </h2>
          </Flex>
          <hr style={{ marginTop: 10, marginBottom: 10 }} />
          <PersonalDetails data={quotation} />
          <Services data={quotation} />
          <ProjectDetails
            data={quotation}
            solution={quotation.quotationsolutions[0]}
          />
          <Samples data={quotation} />

          {userRol === "Administrador" ? (
            <AdminCase
              data={quotation}
              quoteFunction={openCreateBudget}
              closeFunction={closeModal}
              onlySee={onlySee}
            />
          ) : (
            <ClientCase
              data={quotation}
              acceptFunction={clientNextFunction}
              closeFunction={closeModal}
              onlySee={onlySee}
            />
          )}
        </Flex>
      </Modal>
      {/* Admin functions */}
      <CreateBudget
        data={quotation}
        isOpen={isOpenAdmin}
        closeModal={closeModalAdmin}
        isMobile={isMobile}
        backFunction={backFunction}
        nextFunction={nextFunction}
      />
      <ConfirmAction
        isOpen={isOpenConfirm}
        closeModal={closeModalConfirm}
        width={isMobile ? 300 : 400}
        height={isMobile ? 200 : 250}
        action={"Send quotation?"}
        question={"Are you sure you want to send this quotation?"}
        cancelFunction={cancelAdminFunction}
        cancelText={"No, keep it"}
        confirmFunction={confirmAdminFunction}
        confirmText={"Yes, send"}
      />
      {/* Client functions */}
      <ConfirmAction
        isOpen={isOpenClient}
        closeModal={closeModalClient}
        width={isMobile ? 300 : 400}
        height={isMobile ? 200 : 250}
        action={"Be careful"}
        question={
          "If you accept, we will redirect you to the chat with our team, to inform you about the payment methods."
        }
        cancelFunction={cancelClientFunction}
        cancelText={"No, decline"}
        confirmFunction={confirmClientFunction}
        confirmText={"Yes, accept"}
      />
    </>
  );
}
