import React from "react";
import style from "../../../styles/progress.module.css";
// assets
import checkIcon from "../../../../../../assets/img/icons/check-white.svg";
// components
import { Flex } from "@chakra-ui/react";

const ProgressItem = ({ step, stepName, isActive, currentStep }) => {
  return (
    <div
      className={`${style.item} ${isActive ? style.active : style.inactive}`}
    >
      <div className={style.itemBulletContainer}>
        {step !== 1 && (
          <hr
            className={`${style.line} ${
              (isActive && step === 2) || (isActive && step === 3)
                ? style.activeLine
                : style.inactiveLine
            }`}
          />
        )}
        <div className={style.itemBullet}>
          {currentStep > step ? (
            <img src={checkIcon} alt="check" />
          ) : (
            <p>{step}</p>
          )}
        </div>
      </div>
      <div className={style.itemName}>
        <p>{stepName}</p>
      </div>
    </div>
  );
};

export default function ProgressBar({ currentStep }) {
  const steps = [
    { number: 1, name: "Personal Details" },
    { number: 2, name: "Services" },
    { number: 3, name: "Project Details" },
  ];

  return (
    <Flex className={style.progressBar}>
      {steps.map((step, index) => (
        <ProgressItem
          key={index}
          step={step.number}
          stepName={step.name}
          isActive={step.number <= currentStep}
          currentStep={currentStep}
        />
      ))}
    </Flex>
  );
}
