import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// actions
import { getAllUsers } from "redux/actions/user";
// components
import { Flex } from "@chakra-ui/react";
import UsersTable from "./components/UsersTable/UsersTable";
import Loader from "components/loader/Loader";
// variables
import { columnsDataDevelopment } from "./variables/columnsDataUsers";

export default function Users() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const clients = useSelector((state) => state.user.allUsers);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(getAllUsers())
      .then(function () {
        setIsLoading(false);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [user]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Flex pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <UsersTable columnsData={columnsDataDevelopment} tableData={clients} />
    </Flex>
  );
}
